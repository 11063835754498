import { DayOfWeek } from '@fluentui/react-calendar-compat';
import { Field, Input, InputProps, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { CalendarMonthRegular, ClockRegular } from '@fluentui/react-icons';
import { TimePicker } from '@fluentui/react-timepicker-compat';
import { format, parse } from 'date-fns';
import { ChangeEvent, useState } from 'react';
import { Controller } from 'react-hook-form';

import { mobile, validate } from './helpers';
import { ControllerFieldProps } from './models';

export type DateTimeFieldProps = Omit<InputProps, 'type'> & {
    label?: string;
};

type OnChange = (...event: any[]) => void;
type OptionalDate = Date | null | undefined;

const dateChanged = (onChange: OnChange, current: OptionalDate, selected: OptionalDate) => {
    if (!current) {
        onChange(selected);
    } else if (selected) {
        const value = new Date(current.getTime());

        value.setFullYear(selected.getFullYear());
        value.setMonth(selected.getMonth());
        value.setDate(selected.getDate());

        onChange(value);
    } else {
        onChange(null);
    }
};

export const DateTimeField: React.FC<DateTimeFieldProps & ControllerFieldProps> = ({ control, rules, label, className, ...props }) => {
    const classes = useStyles();

    const [time, setTime] = useState<string>();

    const onTime = (ev: ChangeEvent<HTMLInputElement>) => {
        setTime(ev.target.value);
    };

    return (
        <Controller
            defaultValue={undefined}
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => {
                const { onChange, onBlur, value, ref } = field;
                const dateValue = typeof value === 'string' ? new Date(value) : value;
                const timeValue = time ? time : dateValue ? format(dateValue, 'HH:mm') : '';

                return (
                    <Field label={label} {...validate(fieldState)} className={className}>
                        {mobile ? (
                            <Input
                                type="datetime-local"
                                {...props}
                                {...field}
                                contentAfter={<CalendarMonthRegular />}
                                onChange={(e) => field.onChange(e.target.value || undefined)}
                                value={value ?? ''}
                            />
                        ) : (
                            <div className={classes.input}>
                                <DatePicker
                                    {...props}
                                    input={{ className: classes.dateInput }}
                                    className={mergeClasses(props.input?.className, classes.date)}
                                    onBlur={onBlur}
                                    ref={ref}
                                    value={dateValue ?? null}
                                    allowTextInput
                                    showMonthPickerAsOverlay
                                    showGoToToday
                                    parseDateFromString={(input: string) => parse(input, 'dd/MM/yyyy', 0)}
                                    formatDate={(date?: Date) => (date ? format(date, 'dd/MM/yyyy') : '')}
                                    firstDayOfWeek={DayOfWeek.Monday}
                                    onSelectDate={(date) => dateChanged(onChange, value, date)}
                                />
                                <TimePicker
                                    freeform
                                    hourCycle="h23"
                                    dateAnchor={dateValue}
                                    selectedTime={dateValue}
                                    value={timeValue ?? null}
                                    onBlur={onBlur}
                                    onTimeChange={(_, data) => {
                                        if (!data.errorType) {
                                            onChange(data.selectedTime);
                                        } else if (data.errorType === 'invalid-input') {
                                            control?.setError(props.name, { message: 'Invalid time value' });
                                        }
                                    }}
                                    input={{ className: classes.timeInput, maxLength: 5 }}
                                    expandIcon={<ClockRegular />}
                                    className={classes.time}
                                    onInput={onTime}
                                />
                            </div>
                        )}
                    </Field>
                );
            }}
        />
    );
};

const useStyles = makeStyles({
    input: {
        display: 'flex',
        justifyContent: 'stretch',
        overflow: 'hidden',
    },
    date: {
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
        borderRightWidth: '0',
        flexGrow: '1',
        flexShrink: '1',
        ':after': {
            borderBottomRightRadius: '0',
        },
    },
    dateInput: {
        width: '1px',
        flex: '1 1',
    },
    time: {
        minWidth: '0',
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
        borderLeftWidth: '0',
        ':after': {
            borderBottomLeftRadius: '0',
        },
        ':before': {
            content: '" "',
            height: '50%',
            position: 'absolute',
            left: '-1px',
            borderRight: `1px solid ${tokens.colorNeutralStroke1}`,
        },
    },
    timeInput: {
        width: '3em',
        flex: '0 0',
    },
});
