import { HubConnectionBuilder, HubConnectionState, LogLevel } from '@microsoft/signalr';
import { useEffect, useState } from 'react';

import { getToken, msal } from '../common';
import { eventHubs } from '../config';

export const hub = new HubConnectionBuilder()
    .withUrl(eventHubs.rptSvcEvhUrl, {
        accessTokenFactory: () => getToken(msal).then((token) => token.accessToken),
    })
    .configureLogging(LogLevel.Information)
    .build();

export const useHub = () => {
    const [state, setState] = useState<HubConnectionState>(hub.state);
    const [error, setError] = useState();

    useEffect(() => {
        setError(undefined);

        if (!hub) {
            setState(HubConnectionState.Disconnected);
            return;
        }

        setState((state) => {
            if (hub.state !== state) {
                return hub.state;
            }

            return state;
        });

        let isMounted = true;

        const updateState = () => {
            if (isMounted) {
                setState(hub.state);
            }
        };

        hub.onclose(updateState);
        hub.onreconnected(updateState);
        hub.onreconnecting(updateState);

        if (hub.state === HubConnectionState.Disconnected) {
            const startPromise = hub.start().then(updateState).catch(setError);
            updateState();

            return () => {
                startPromise.then(() => hub.stop());
                isMounted = false;
            };
        }

        return () => hub.stop();
    }, []);

    return { state, error };
};

export const useHubListener = <T = any>(methodName: string, method: (data: T) => void) => {
    useHub();

    useEffect(() => {
        const callback = (data: string) => method(JSON.parse(data));

        hub.on(methodName, callback);

        return () => hub.off(methodName, callback);
    }, [method, methodName]);
};
