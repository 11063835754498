import { makeStyles } from '@fluentui/react-components';
import React, { forwardRef, Fragment, PropsWithChildren, useCallback, useMemo, useRef } from 'react';

import { calculateLogoDimensions, createQrCode, ErrorCorrectionLevel } from './helper';

type QrCodeProps = {
    content: string;
    /** number between 0 (= square) and 1 (= circle) */
    moduleRoundness?: number;
    /** number between 0 (= 0%) and 1 (= 100%) */
    moduleScale?: number;
    moduleColor?: string;
    /** number between 0 (= square) and 1 (= circle) */
    positionRingRoundness?: number;
    positionRingColor?: string;
    /** number between 0 (= square) and 1 (= circle) */
    positionCenterRoundness?: number;
    positionCenterColor?: string;
    backgroundColor?: string;
    logoAspectRatio?: number;
    /** number of modules used as quiet zone */
    quietZone?: number;
    errorCorrectionLevel?: ErrorCorrectionLevel;
};

export const QrCode = forwardRef<HTMLDivElement, PropsWithChildren<QrCodeProps>>((props, ref) => {
    const {
        content,
        moduleRoundness = 0,
        moduleScale = 1,
        moduleColor = '#000',
        positionRingRoundness = 0,
        positionRingColor = '#000',
        positionCenterRoundness = 0,
        positionCenterColor = '#000',
        backgroundColor = 'transparent',
        logoAspectRatio = 1,
        quietZone = 4,
        errorCorrectionLevel = 'Q',
        children,
    } = props;

    const classes = useStyles();

    const qrCode = useMemo(() => createQrCode(content, errorCorrectionLevel), [content, errorCorrectionLevel]);
    const qrCodeRef = useRef<SVGSVGElement>(null);

    const moduleCount = qrCode.modules;
    const totalModuleCount = qrCode.modules + quietZone * 2;
    const coordinateShift = totalModuleCount / 2;

    const logoDimensions = useMemo(() => calculateLogoDimensions(qrCode, logoAspectRatio), [logoAspectRatio, qrCode]);

    const isLogo = useCallback(
        (row: number, column: number) => {
            return (
                row >= logoDimensions.y.start &&
                row < logoDimensions.y.end &&
                column >= logoDimensions.x.start &&
                column < logoDimensions.x.end
            );
        },
        [logoDimensions],
    );

    const renderQrModule = useCallback(
        (count: number, hasLogo: boolean, coordinateShift: number) => {
            const modules: React.ReactNode[] = [];

            for (let column = 0; column < count; column += 1) {
                const positionX = column + quietZone;

                for (let row = 0; row < count; row += 1) {
                    const positionY = row + quietZone;

                    if (!qrCode.isDark(column, row) || qrCode.isPositioningElement(row, column) || (hasLogo && isLogo(row, column))) {
                        // only render "dark" modules which are not part of the positioning corners and not behind the logo
                        continue;
                    }

                    modules.push(
                        <rect
                            key={`module-${column}-${row}`}
                            fill={moduleColor}
                            rx={0.5 * moduleRoundness * moduleScale}
                            x={positionX - 0.5 - coordinateShift}
                            y={positionY - 0.5 - coordinateShift}
                            height={1 * moduleScale}
                            width={1 * moduleScale}
                        />,
                    );
                }
            }

            return modules;
        },
        [quietZone, qrCode, isLogo, moduleColor, moduleRoundness, moduleScale],
    );

    return (
        <div className={classes.root} ref={ref}>
            <svg
                ref={qrCodeRef}
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                className={classes.qrCode}
                viewBox={`${0 - coordinateShift - 0.5} ${0 - coordinateShift - 0.5} ${totalModuleCount} ${totalModuleCount}`}
                preserveAspectRatio="xMinYMin meet"
            >
                {/* render background */}
                <rect width="100%" height="100%" fill={backgroundColor} x={0 - coordinateShift - 0.5} y={0 - coordinateShift - 0.5} />
                {/* render position indicators */}
                {[
                    // indicators are always 7 modules across
                    { x: 0 + quietZone, y: 0 + quietZone },
                    { x: moduleCount - 7 + quietZone, y: 0 + quietZone },
                    { x: 0 + quietZone, y: moduleCount - 7 + quietZone },
                ].map(({ x, y }, index) => (
                    <Fragment key={`position-indicator-${index}`}>
                        <rect
                            stroke={positionRingColor}
                            fillOpacity={0}
                            strokeWidth={1}
                            x={x - coordinateShift}
                            y={y - coordinateShift}
                            height={6}
                            width={6}
                            rx={3 * positionRingRoundness}
                        />
                        <rect
                            fill={positionCenterColor}
                            x={x + 1.5 - coordinateShift}
                            y={y + 1.5 - coordinateShift}
                            height={3}
                            width={3}
                            rx={1.5 * positionCenterRoundness}
                        />
                    </Fragment>
                ))}
                {/* render modules */}
                {renderQrModule(moduleCount, !!children, coordinateShift)}
            </svg>
            {/* render logo */}
            {children && (
                <div
                    className={classes.logoContainer}
                    style={{
                        width: `${((logoDimensions.x.end - logoDimensions.x.start) / totalModuleCount) * 100}%`,
                        height: `${((logoDimensions.y.end - logoDimensions.y.start) / totalModuleCount) * 100}%`,
                    }}
                >
                    {children}
                </div>
            )}
            {/*add a (masked) transparent img on top to make the clarity masking work with svgs*/}
            <img
                alt=""
                data-clarity-mask="true"
                className={classes.dataMask}
                src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            />
        </div>
    );
});

const useStyles = makeStyles({
    root: {
        position: 'relative',
        width: `100%`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        aspectRatio: 1,
    },
    qrCode: {
        height: '100%',
        aspectRatio: 1,
    },
    logoContainer: {
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',

        '> *': {
            height: '100%',
        },
    },
    dataMask: {
        position: 'absolute',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
    },
});
