import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

type PageTitleState = {
    title: string | undefined;
};

const initialState: PageTitleState = { title: undefined };

const { useGlobalState } = createGlobalState(initialState);

export const usePageTitle = (title?: string): ReturnType<typeof useGlobalState> => {
    const [currentTitle, setTitle] = useGlobalState('title');

    useEffect(() => {
        if (title) {
            setTitle(title);

            return () => setTitle(undefined);
        }
    }, [setTitle, title]);

    return [currentTitle, setTitle];
};
