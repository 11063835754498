import { v4 as uuid } from 'uuid';

import { generate, redeem } from './api';
import { getRefreshToken as getRefreshTokenFromStorage, storeRefreshToken } from './storage';

export function generateEmergencyRefreshToken(accessToken: string) {
    const refreshTokenId = getRefreshTokenFromStorage()?.id ?? uuid();

    generate(refreshTokenId, accessToken).then((refreshToken) => {
        storeRefreshToken(refreshToken);
    });
}

export async function redeemEmergencyRefreshToken() {
    const refreshToken = getRefreshTokenFromStorage();

    if (!refreshToken) {
        throw Error('No refresh token found in storage');
    }

    const newRefreshToken = await redeem(refreshToken);

    storeRefreshToken(newRefreshToken);

    return newRefreshToken;
}
