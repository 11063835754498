import { Button, Menu, MenuList, MenuPopover, MenuTrigger, useOverflowMenu } from '@fluentui/react-components';
import { bundleIcon, MoreHorizontalFilled, MoreHorizontalRegular } from '@fluentui/react-icons';

import { FilterProps } from './Filter';
import { FilterOverflowMenuItem } from './FilterOverflowMenuItem';

const MoreHorizontal = bundleIcon(MoreHorizontalFilled, MoreHorizontalRegular);

export type FilterOverflowMenuProps = {
    filters: FilterProps[];
    onClick?: (filter: FilterProps) => void;
};

export const FilterOverflowMenu: React.FC<FilterOverflowMenuProps> = ({ filters, onClick }) => {
    const { ref, isOverflowing } = useOverflowMenu<HTMLButtonElement>();

    if (!isOverflowing) {
        return null;
    }

    return (
        <Menu>
            <MenuTrigger disableButtonEnhancement>
                <Button appearance="transparent" ref={ref} icon={<MoreHorizontal />} />
            </MenuTrigger>
            <MenuPopover>
                <MenuList>
                    {filters.map((filter) => (
                        <FilterOverflowMenuItem
                            key={filter.name}
                            name={filter.name}
                            title={filter.title}
                            onClick={() => onClick?.(filter)}
                        />
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
