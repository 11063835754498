import axios, { AxiosError, AxiosResponse, RawAxiosRequestHeaders } from 'axios';
import axiosRetry from 'axios-retry';
import saveAs from 'file-saver';
import qs from 'qs';

import { apiExtraHeader, defaultRequestTimeout, defaultUploadDownloadTimeout, pagingSize, requestConfigs } from '../config';
import { setStaleData } from '../hooks/use-stale-data';
import { getToken, msal } from './auth';
import { EntityWithEtag, List, ListFilter, PagedList } from './models';

export enum CommonApiErrorCode {
    EntityVersionMismatch = 'EntityVersionMismatch',
    Validation = 'Validation',
    DuplicateEntity = 'DuplicateEntity',
}

export type ApiErrorResponse = {
    code: CommonApiErrorCode | string;
    message: string;
    correlationId: string;
};

export const InterceptorSkipHeader = 'X-Skip-Interceptor';
export enum Interceptor {
    AuthInterceptor = 'AuthInterceptor',
}

export const api = axios.create({
    headers: { Accept: `application/json` },
    paramsSerializer: { serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }) },
    timeout: defaultRequestTimeout,
});

axiosRetry(api, {
    retries: requestConfigs.retries,
    retryDelay: (retryCount) => retryCount * requestConfigs.delay,
});

api.interceptors.request.use(
    async (request) => {
        if (request.headers?.[InterceptorSkipHeader] !== Interceptor.AuthInterceptor) {
            const { accessToken } = await getToken(msal);

            request.headers.set('Authorization', `Bearer ${accessToken}`);

            if (!request.headers.has('Accept')) {
                request.headers.set('Accept', `application/json`);
            }

            Object.entries(apiExtraHeader).forEach(([name, value]) => request.headers.set(name, value));
        }

        return request;
    },
    (error) => Promise.reject(error),
);

api.interceptors.request.use((request) => {
    const isFileDownload = request.responseType?.toLowerCase() === 'blob';
    const isFileUpload = [FormData, File, Blob].some((type) => request.data instanceof type);

    if (isFileDownload || isFileUpload) {
        request.timeout = defaultUploadDownloadTimeout;
    }

    return request;
});

api.interceptors.response.use(
    (response) => response,
    (error: AxiosError<ApiErrorResponse>) => {
        // 409 - EntityVersionMismatch indicates that the data was changed in the background
        if (error.response?.status === 409 && error.response?.data?.code === CommonApiErrorCode.EntityVersionMismatch) {
            setStaleData(true);
        }

        return Promise.reject(error);
    },
);

export function extractFilename(response: AxiosResponse<Blob>) {
    const contentDisposition = response.headers['content-disposition'] || '';
    const matches = /filename="([^;]+)"/gi.exec(contentDisposition);

    return matches?.[1]?.trim() || 'Backoffice-Generated-Document';
}

export function appendEtag<T extends EntityWithEtag>(response: AxiosResponse<T>) {
    const etag = response.headers['etag'];

    if (etag) {
        response.data.etag = etag;
    }

    return response;
}

export function getEtagHeader(etag?: string) {
    const headers: RawAxiosRequestHeaders = {};

    if (etag) {
        headers['if-match'] = etag;
    }

    return headers;
}

export function getResponse<T>(response: AxiosResponse<T>) {
    return response.data;
}

export function getListResponse(filter: ListFilter, exclusiveLimit = false) {
    return function <T>(response: AxiosResponse<List<T>>): PagedList<T> {
        const list = getResponse(response);
        const size = filter.limit ?? pagingSize;

        let items = list.items ?? [];

        if (filter.from && items.length) {
            items = items.reverse();
        }

        const next =
            items.length >= size
                ? // if exclusive limit the next item needs to be the last item of the returned list
                  // for backwards navigation we still need to load +1 elements
                  exclusiveLimit
                    ? items.pop() && items.at(-1)
                    : items.pop()
                : undefined;

        return { items, next };
    };
}

export function save(response: AxiosResponse<Blob>, filename?: string) {
    saveAs(response.data, filename || extractFilename(response));
}

export function sanitize(data?: any): any | undefined {
    if (data === undefined || data === null || data === '') {
        return;
    }

    if (Array.isArray(data)) {
        const sanitizedData: any[] = [];

        data.forEach((item) => {
            const sanitizedItem = sanitize(item);

            if (sanitizedItem !== undefined) {
                sanitizedData.push(sanitizedItem);
            }
        });

        if (sanitizedData.length) {
            return sanitizedData;
        }
    } else if (typeof data === 'object') {
        const sanitizedData: any = {};

        Object.entries(data).forEach(([key, value]) => {
            const sanitizedValue = sanitize(value);

            if (sanitizedValue !== undefined) {
                sanitizedData[key] = sanitizedValue;
            }
        });

        if (Object.keys(sanitizedData).length) {
            return sanitizedData;
        }
    } else {
        return data;
    }
}
