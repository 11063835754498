import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { forwardRef, HTMLAttributes } from 'react';

import { Logo } from '../../layout/Logo';
import { QrCode } from './QrCode';

type BrandedQrCodeProps = Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'> & {
    content: string;
};

// currently hardcoded, maybe calculate on render?
const LOGO_ASPECT_RATIO = 1.6;
const CODE_SIZE = 240;

export const BrandedQrCode = forwardRef<HTMLDivElement, BrandedQrCodeProps>(({ content, className, ...props }, ref) => {
    const classes = useStyles();

    return (
        <div className={mergeClasses(classes.code, className)} {...props}>
            <QrCode
                ref={ref}
                content={content}
                moduleColor={tokens.colorNeutralForeground1}
                moduleScale={0.75}
                moduleRoundness={1}
                positionCenterColor={tokens.colorNeutralForeground1}
                positionCenterRoundness={1}
                positionRingColor={tokens.colorNeutralForeground1}
                positionRingRoundness={0.65}
                quietZone={2}
                logoAspectRatio={LOGO_ASPECT_RATIO}
            >
                <Logo className={classes.logo} fill={tokens.colorNeutralForeground1} />
            </QrCode>
        </div>
    );
});

const useStyles = makeStyles({
    code: {
        width: '100%',
        maxWidth: `${CODE_SIZE}px`,
    },
    logo: {
        padding: `12px`,
    },
});
