import QrCodeGenerator from 'qrcode-generator';

export type ErrorCorrectionLevel = 'L' | 'M' | 'Q' | 'H';

export type QrCode = {
    content: string;
    contentBits: number;
    capacityBits: number;
    modules: number;
    version: number;
    errorCorrectionLevel: ErrorCorrectionLevel;
    isDark: (x: number, y: number) => boolean;
    isPositioningElement: (x: number, y: number) => boolean;
};

const ERROR_CORRECTION_PERCENTAGE: Record<ErrorCorrectionLevel, number> = {
    L: 0.07,
    M: 0.15,
    Q: 0.25,
    H: 0.3,
};

// define the capacity of QR code for different error correction levels and versions
// https://www.qrcode.com/en/about/version.html
const VERSION_BYTE_CAPACITY: Record<ErrorCorrectionLevel, number[]> = {
    L: [
        17, 32, 53, 78, 106, 134, 154, 192, 230, 271, 321, 367, 425, 458, 520, 586, 644, 718, 792, 858, 929, 1003, 1091, 1171, 1273, 1367,
        1465, 1528, 1628, 1732, 1840, 1952, 2068, 2188, 2303, 2431, 2563, 2699, 2809, 2953,
    ],
    M: [
        14, 26, 42, 62, 84, 106, 122, 152, 180, 213, 251, 287, 331, 362, 412, 450, 504, 560, 624, 666, 711, 782, 860, 914, 1000, 1062, 1128,
        1193, 1267, 1373, 1455, 1541, 1631, 1725, 1812, 1914, 1988, 2096, 2216, 2334,
    ],
    Q: [
        11, 20, 32, 46, 60, 74, 86, 108, 130, 154, 180, 206, 244, 261, 295, 325, 367, 397, 442, 484, 512, 568, 614, 664, 718, 754, 808, 871,
        911, 985, 1033, 1115, 1171, 1231, 1286, 1354, 1426, 1502, 1582, 1666,
    ],
    H: [
        7, 13, 22, 32, 42, 52, 62, 77, 91, 107, 123, 139, 154, 173, 191, 208, 235, 247, 259, 284, 310, 325, 341, 359, 379, 399, 419, 442,
        461, 482, 509, 530, 552, 574, 601, 625, 648, 673, 703, 734,
    ],
};

const VERSION_MODULES = [
    21, 25, 29, 33, 37, 41, 45, 49, 53, 57, 61, 65, 69, 73, 77, 81, 85, 89, 93, 97, 101, 105, 109, 113, 117, 121, 125, 129, 133, 137,
];

const calculateQrCodeVersion = (content: string, errorCorrectionLevel: ErrorCorrectionLevel) => {
    const contentLength = new TextEncoder().encode(content).length;
    // find the capacity for the given error correction level
    const capacity = VERSION_BYTE_CAPACITY[errorCorrectionLevel];
    const version = capacity.findIndex((cap) => contentLength <= cap) + 1;
    if (version <= 0) throw new Error('QrCode content too long');

    return {
        version,
        capacityBits: capacity[version - 1] * 8,
        contentBits: contentLength * 8,
        modules: VERSION_MODULES[version - 1],
    };
};

export const createQrCode = (content: string, errorCorrectionLevel: ErrorCorrectionLevel = 'Q'): QrCode => {
    const code = QrCodeGenerator(0, errorCorrectionLevel);
    code.addData(content, 'Byte');
    code.make();

    const { contentBits, capacityBits, version, modules } = calculateQrCodeVersion(content, errorCorrectionLevel);

    const isPositioningElement = (row: number, column: number) => {
        const elemWidth = 7;
        return row <= elemWidth
            ? column <= elemWidth || column >= modules - elemWidth
            : column <= elemWidth
              ? row >= modules - elemWidth
              : false;
    };

    return {
        content,
        errorCorrectionLevel,
        contentBits,
        capacityBits,
        version,
        modules,
        isDark: code.isDark,
        isPositioningElement,
    };
};

export const calculateLogoDimensions = (qrCode: QrCode, logoAspectRatio: number) => {
    const { capacityBits, contentBits, version, modules } = calculateQrCodeVersion(qrCode.content, qrCode.errorCorrectionLevel);

    const qrModules =
        // number of modules which can be error corrected (for full capacity)
        contentBits * ERROR_CORRECTION_PERCENTAGE[qrCode.errorCorrectionLevel] +
        // add unused data capacity
        (capacityBits - contentBits);

    // calculate the maximum logo size maintaining the given aspect ratio
    let maxWidth = Math.ceil(Math.sqrt(qrModules / logoAspectRatio) * logoAspectRatio);
    let maxHeight = Math.ceil(Math.sqrt(qrModules / logoAspectRatio));

    // make sure we use nearest (smaller) odd number (because QR codes are always odd number of modules)
    maxWidth -= maxWidth % 2 === 0 ? 1 : 0;
    maxHeight -= maxHeight % 2 === 0 ? 1 : 0;

    // never obscure the timing patterns and quiet zones
    const min = modules - (version > 2 ? 9 : 8) * 2;

    maxWidth = Math.min(maxWidth, min);
    maxHeight = Math.min(maxHeight, min);

    // now that we know the actual size of the logo calculate where it should be positioned
    const center = modules / 2;

    const startX = Math.floor(center - maxWidth / 2);
    const endX = startX + maxWidth;
    const startY = Math.floor(center - maxHeight / 2);
    const endY = startY + maxHeight;

    return {
        x: { start: startX, end: endX },
        y: { start: startY, end: endY },
    };
};
