import { MsalProvider } from '@azure/msal-react';
import { FluentProvider } from '@fluentui/react-components';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { msal, TranslationProvider } from './common';
import { theme } from './theme';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <React.StrictMode>
        <FluentProvider theme={theme}>
            <MsalProvider instance={msal}>
                <TranslationProvider>
                    <App />
                </TranslationProvider>
            </MsalProvider>
        </FluentProvider>
    </React.StrictMode>,
);
