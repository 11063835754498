import { defaultLanguage, supportedLanguages } from '../config';

const browserLanguage = window.navigator.language;
const language = supportedLanguages.find((language) => language.locale === browserLanguage) ?? defaultLanguage;

export const formatNumber = (value: number | undefined) => {
    if (value === null || value === undefined) {
        return;
    }

    return Number(value).toLocaleString(language.locale, { maximumFractionDigits: 2 });
};

export type FormattedNumberProps = {
    value?: number;
};

export const FormattedNumber: React.FC<FormattedNumberProps> = (props) => {
    return <>{formatNumber(props.value)}</>;
};
