import { FieldProps } from '@fluentui/react-components';
import { ControllerFieldState, FieldPath, FieldValues, FormState, get } from 'react-hook-form';

export const mobile = /Android|iPhone/i.test(navigator.userAgent);
export type ValidationProps = Pick<FieldProps, 'validationState' | 'validationMessage' | 'validationMessageIcon'>;

export function validate(state: ControllerFieldState): ValidationProps;
export function validate<TModel extends FieldValues, TProperty extends FieldPath<TModel>>(
    state: FormState<TModel>,
    name: TProperty,
): ValidationProps;
export function validate<TModel extends FieldValues, TProperty extends FieldPath<TModel>>(
    state: FormState<TModel> | ControllerFieldState,
    name?: TProperty,
): ValidationProps {
    const error = 'errors' in state ? get(state.errors, name) : state.error;

    if (error) {
        return {
            validationState: 'error',
            // it is a component slot, so if no message we want null so the component is not rendered empty
            validationMessage: error.message ?? null,
            // it is a component slot, so if no message we want null so the component is not rendered empty
            // undefined allows default behavior
            validationMessageIcon: error.message ? undefined : null,
        };
    }

    return {};
}
