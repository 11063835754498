import { createDarkTheme, createLightTheme } from '@fluentui/react-components';

export const theme = createLightTheme({
    10: '#010305',
    20: '#0f1920',
    30: '#112938',
    40: '#11364b',
    50: '#0e435f',
    60: '#045173',
    70: '#1e5e81',
    80: '#376b8b',
    90: '#4c7896',
    100: '#5f86a1',
    110: '#7293ac',
    120: '#85a1b7',
    130: '#98afc2',
    140: '#aabdcd',
    150: '#bdccd8',
    160: '#d0dbe3',
});

export const darkTheme = createDarkTheme({
    10: '#010305',
    20: '#0f1920',
    30: '#112938',
    40: '#11364b',
    50: '#0e435f',
    60: '#045173',
    70: '#1e5e81',
    80: '#376b8b',
    90: '#4c7896',
    100: '#5f86a1',
    110: '#7293ac',
    120: '#85a1b7',
    130: '#98afc2',
    140: '#aabdcd',
    150: '#bdccd8',
    160: '#d0dbe3',
});
