import { MenuItem, MenuItemProps, useIsOverflowItemVisible } from '@fluentui/react-components';

import { FilterProps } from './Filter';

export type FilterOverflowMenuItemProps = FilterProps & {
    onClick: MenuItemProps['onClick'];
};

export const FilterOverflowMenuItem: React.FC<FilterOverflowMenuItemProps> = (props) => {
    const isVisible = useIsOverflowItemVisible(props.name);

    if (isVisible) {
        return null;
    }

    return <MenuItem onClick={props.onClick}>{props.title}</MenuItem>;
};
