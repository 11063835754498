import {
    Button,
    Dialog,
    DialogActions,
    DialogBody,
    DialogContent,
    DialogSurface,
    DialogTitle,
    DialogTrigger,
} from '@fluentui/react-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { useStaleData } from '../hooks';

export const StaleDataDialog: React.FC = () => {
    const [staleData, setStaleData] = useStaleData();
    const navigate = useNavigate();
    const location = useLocation();

    const reloadPage = () => {
        const currentPath = location.pathname;

        // navigate away from current path and back to rerender/reload current Route
        navigate(`/reload`, { replace: true });
        setTimeout(() => navigate(currentPath, { replace: true }));
        setStaleData(false);
    };

    return (
        <Dialog open={staleData} onOpenChange={(_, data) => setStaleData(data.open)}>
            <DialogSurface>
                <DialogBody>
                    <DialogTitle>Operation failed</DialogTitle>
                    <DialogContent>
                        The data has been changed by another user or the system since you opened this page. Reload the page and repeat your
                        last operation. Your changes will be lost.
                    </DialogContent>
                    <DialogActions>
                        <DialogTrigger disableButtonEnhancement>
                            <Button appearance="secondary">Cancel</Button>
                        </DialogTrigger>
                        <Button appearance="primary" onClick={reloadPage}>
                            Reload
                        </Button>
                    </DialogActions>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};
