export const versions = [
    {
        version: '0.55',
        date: 'July 2024',
        changes: [
            '[CRA][P1.5] Registration - PEP - Ask customer if PEP Tier 1 only (no family members)',
            '[CRA][P1.1] Registration - If Country of Residence = blacklisted country => reject the registration',
            'Poa Kyc request -  remove Schufa document type from Info and type selector',
            'Kyc Process - Investigate drop in the upload phase for poi',
            'Create KycRequest in Backoffice: validate that customer with this id exists',
            'Disallow QR code generation when trxs are disallowed',
            'Block Distributor should disallow Deposits and allow Withdrawals',
            'Add Transaction Intent section for Compliance officer Role',
            'Add message in document state change dialog to indicate KYC request will not be affected',
            'Extend Backoffice-Role: Account Management Coordinator',
        ],
    },
    {
        version: '0.54',
        date: 'June 2024',
        changes: [
            'Addition of Personal Details in Customer Portal ',
            'Email Verification UI extensions',
            'KYC Request Flows - Skip button addition in header',
            'Legacy customer -> Zastrpay Portal (Duplicate merge handling)',
            'Failed Tech Issues - GBG - Reduce timeframe for case creation',
            'Create Data Quality Check cases with delay',
            'Backoffice Email OTP Actions - Audit Trail',
            'Customer Authentication: Merge validated login credentials when customer is merged',
            'Backoffice: display only relevant document properties',
            'Backoffice: add the „external Reference“ (=AKID) to the overview of the Shops per distributor + create the option to search for it',
            'Customer registration: change DOB input field from datepicker to simple input',
            'Add Users section for Compliance Officer Role + other permissions as per latest reviewed XLS',
        ],
    },
    {
        version: '0.53',
        date: 'May 2024',
        changes: [
            'Decommission Daily All Tipico Distributor Trxs Export File',
            'Registration (Redirect + Portal): New Design for Additional Questions',
            'Email verification + migrate to KYC Request',
            'NotifSvc: customize welcome email for DistributorManager/DistributorUser role',
            'Extend Customer Merge/Unmerge to cover Account Balance',
            'Reject Option for Customer Data Modifications',
            'CustKycSvc: Merge and unmerge Kyc requests of all types',
            'Create ticket for old PeriodicReview CustomerVerification always when document submitted',
        ],
    },
    {
        version: '0.52',
        date: 'April 2024',
        changes: [
            'Improve error message when customerId provided but phone number mismatch ',
            'zastrpay.de -> zastrpay.com',
            'Mark customer email as verified',
            'Add phone number placeholder for the format',
            'Create events for portal on Google analytics',
            'Extend Merchant User permissions to Backoffice',
            'Pending Customer verifications (no CRM tickets)',
            'Add View Remark rights + KYC Requests + ReKYC Verifications etc. for Compliance Officer role ',
            'CESOP Phase 1',
            'Affiliate Service',
            'Prefill phone number',
            'Add phone number placeholder for the format',
            'Change/Extend rules for Data Quality Checks Dynamics CRM Queue',
            'Business Central Integration: Adapt folder structure for settlement export',
            'Delete user in Backoffice -> remove from POS App (frontend-only)',
            'Check if refund and reversal is allowed for the role when merchantId is provided in allowedRequestValues',
            'Email verification + migrate to KYC Request',
        ],
    },
    {
        version: '0.51',
        date: 'March 2024',
        changes: [
            'Validate DOB in UI ',
            'Pending Customer verifications (no CRM tickets)',
            'POA Kyc Requests - fix all existing to be due in 30 days',
            'Duplicate Customer Registration - detect early & show Reg QR Code ',
            'Shop PendingReview -> Closed directly',
            'Backoffice: Add links to SMS OTPS, Email OTPS, Pins, Redirect Sessions, Transaction Intents, from customer profile page ',
            'Map legacy shops upon transaction (over shop external reference) ',
            'Remove "Change Phone Number" from Reg Info page ',
            'Show Info/Validate that ID has to be scanned front and back ',
            'Fix error handling for phone verification',
            'Instruct customer to upload both pages of ID document',
            'Partial and full Refunds',
            'Add "By State" filter for Redirect Sessions, SMS OTPs, Email OTPS, Pins',
            'Shop List - Filter By Distributor',
            'Shop Details -> Transactions (Combined Filters)',
            'Deposit/Withdrawal cancelled -> QR Code cancelled',
            'Instruct customer to upload both pages of ID document',
            '',
            '',
        ],
    },
    {
        version: '0.50',
        date: 'February 2024',
        changes: [
            'Delayed email notification to customer for registration QR Code',
            'Backoffice Welcome Email Notification customized by role (Shop Operator)',
            'Dynamic CRM Case creation for SOW or SOF DOCUMENT submission by customer',
            'Customer To Merchant Transfer',
            'Merchant To Customer Transfer',
            'Map legacy shops upon transaction (over shop external reference)',
            'Add new document rejection reasons',
        ],
    },
    {
        version: '0.49',
        date: 'January 2024',
        changes: [
            'Distributor and Merchant integration Public API Documentation',
            'Backoffice: Find RedirectSession, SMS Otps, Email Otps, Pins By Id',
            'Update Roles, incl. 2 new roles and updates to existing',
            'Search Trx Intents and Trxs by ExternalReference',
            'Add registration date to duplicate review screen',
            'Add the StateReason field to the Distributors table and DistributorsView',
            'Do not show closed shops in the dropdown list when creating a new user and assigning ShopOperator role',
            'Audit trail for Limit Account Sets',
            'CustomerRedirect: Addition of Trusted Beneficiary',
            'POA Document: Handle customer notifications for review outcome (Rejection)',
            'Extend Merchant Sample App with Phone Number input',
            'Shop Finder - centre map back to current location',
            'POI: Validate that all documents are reviewed when rejected',
            'Cash To Wallet Transaction',
            'Wallet To Cash Transaction',
            'Make take a photo and upload file buttons "equal"',
            'Missing POI expiration date validation',
            'Rebranding (Zastray)',
            'CustomerPortal: Management of Trusted Beneficiaries',
        ],
    },
    {
        version: '0.48',
        date: 'December 2023',
        changes: [
            'User analytics - Microsoft Clarity Integration ',
            'User analytics KYC Flow',
            'Customer Portal settings',
            'KYC Requests with Documents: Reject and Re-request document',
            'POI Document Upload - Automatic closure of the KYC Request review by using OCR',
            'Tipico Integration: Cancel non-existent qr code - branch TipicoPhase2',
            'KycRequest: pre-fill customerId in create kyc request form',
            'Rebranding to Zastrpay - Frontends and CDNs',
            'CustomerWebApp/POSApp - Re-KYC Flow',
        ],
    },
    {
        version: '0.47',
        date: 'November 2023',
        changes: [
            'SOW: cancel sow kyc request -> flag changes to NotRequired',
            'CustomerPortal: Settings - Change PIN, Phone Number, Email change  ',
            'POI Document expiration should not change the kyc state',
            'Zastrpay Customer Redirect App: Add Popup if customer selects PEP = Yes ',
            'User Tracking/Analytics (aka tracking heatmap UI)',
        ],
    },
    {
        version: '0.46',
        date: 'October 2023',
        changes: [
            'Zastrpay Customer Redirect/Portal: Loading indicator',
            'Zastrpay Customer Portal: Show Existing QR Code',
            'New Monitoring Check: Daily Transaction State DeclinedReason Anomalies',
            'Re-KYC: consider duplicate merge as done periodic re-kyc',
            'Settlement Import File: Possibility to fix or delete + re upload a file via Backoffice',
            'Daily Combined Distributor Trxs Export File',
            'Device Retire',
            'User Device Authorizations: Display username and shop name in the list',
        ],
    },
    {
        version: '0.45',
        date: 'September 2023',
        changes: [
            'Links to High Risk Countries Question',
            'Source of Funds/Wealth Questionnaire and Documents Request',
            'Add Limit Name to the Limits and Limit Accounts list',
            'Show Other Transaction referencing this one on the Transaction details page',
        ],
    },
    {
        version: '0.44',
        date: 'August 2023',
        changes: ['Zastrpay Customer Web Portal'],
    },
    {
        version: '0.43',
        date: 'July 2023',
        changes: [
            'Simplify rules for selecting customers for Data Quality Checks Dynamics CRM Queue',
            'Show latest phone and email (from QR Code Solution) in Customer Profile ',
            'Customer Risk: Audit Trail indexes start from 1',
            'Customer Risk: Trx Audit Trail',
            '1 Operator -> Multiple Shops',
            'Backoffice: German language support (at least for Distributor Manager Role UI screens)',
            'Create a ticket in Dynamics if customer address country changes to non-DE after duplicate merges or customer verifications',
            'Business Central Integration Extensions (FileType)',
            'User Device Authorizations: Add Filter by User ID',
            'Search shop by name',
            'Combined transaction search - add filter by shopID',
            'Create shops in state PendingReview',
            'Devices list in Backoffice incl. search By Id',
        ],
    },
    {
        version: '0.42',
        date: 'June 2023',
        changes: [
            'Zastrpay QR Code Solution Phase 2 (New Customers)',
            'Zastrpay Redirect App: Email verification upon customer registration',
            'Distributor Manager role in C2D Backoffice',
            'Re-KYC: Revise scheduling reason update',
            'SOW Slow Activation (Bulk SOW Flag Update)',
            'CRA High Risk Customer Alert',
        ],
    },
    {
        version: '0.41',
        date: 'May 2023',
        changes: [
            'Customer Country of Birth',
            'Daily Transaction Reconciliation files (Merchant, Distributor)',
            'Audit Trail improvements in C2D Backoffice Admin Tool',
            'Include reopened accounts in the GBG OGM files',
            'Add External Reference attribute to Distributor, Shop, Operator',
        ],
    },
    {
        version: '0.40',
        date: 'April 2023',
        changes: [
            'C2D QR Code Solution Phase 1 (Existing Customers)',
            'Customer Risk Profile Auditing Improvements',
            'Zastrpay-branded websites/redirect/URLs',
        ],
    },
    {
        version: '0.39',
        date: 'March 2023',
        changes: [
            'Anomaly detection: Finetune the queries to remove false positives',
            'Re-KYC: communicate mergedToCustomerId for Cancelled customer verifications',
        ],
    },
    {
        version: '0.38',
        date: 'February 2023',
        changes: ['Customer Q&A: add status badge to the assignment'],
    },
    {
        version: '0.37',
        date: 'January 2023',
        changes: [
            'New Account Closure Reason: SOF/SOW Uncooperative',
            'CustomerVerification: do not allow document types other than POI and POA to be submitted',
        ],
    },
    {
        version: '0.36',
        date: 'December 2022',
        changes: [
            'SOW Questionnaire - Questions & predefined answers',
            'SOF/SOW KYC Flag Reasons',
            'New Account Closure Reason: SOF/SOW Uncooperative',
            'ScaWhitelisted flag on merchant level',
            'Export Customer Transactions to CSV',
            'Other Accounts on Customer Profile Page',
            'New CRA risk rule - nature of business relationship',
            'MS Dynamics CRM - just-in-time contact registration',
        ],
    },
    {
        version: '0.35',
        date: 'November 2022',
        changes: [
            'ScaWhitelisted flag on merchant level',
            'Changes to T&Cs // Extend filtering for download option in back office',
            'Monitoring Alert - Documents not received from Tipico for more than 90 minutes',
            'Reversals and ReversalReverals should not be affected by Limit Thresholds',
            'Remove CustomerInformationRevalidationMissing for ProofOfIdentityExpired verifications',
        ],
    },
    {
        version: '0.34',
        date: 'October 2022',
        changes: [
            'Export for Mass Email/Sms to all Customers ',
            'Re-KYC: Accept data without document on verification with scheduling reason Document Expired/Missing ',
            'CBM Export File Changes ',
            'OGM Upload list of closed account via SFTP ',
        ],
    },
    {
        version: '0.33',
        date: 'September 2022',
        changes: [
            'Export Data Modifications to DWH',
            'Transaction Processing Success Rate Monitoring and Alerting',
            'Missing POA attachments to be automatically updated and trigger Schufa',
            'Maximum expiry date for POIs upon registration',
            'Filter out maiden name from data received during Re-KYC',
            'GBG Modified users file - exclude case sensitive changes',
            'Do not overwrite address if difference only a dot',
        ],
    },
    {
        version: '0.32',
        date: 'August 2022',
        changes: [
            'Re-KYC- Rework Remark Approach',
            'Re-KYC: Dynamics tickets exception',
            'Re-KYC: Run Schufa for all Customer Verification Completions where POA flag = Pending (regardless when it went to Pending)',
            'Re-KYC: Multiple IDs remain in Active state',
            'Re-KYC: handle duplicate documents on data submission',
            'Periodic review for material adverse media flag',
            'Update data quality sample generation logic',
            'Automate notification for user creation/approval/rejection in backoffice',
            'Add distributor name on change request page',
            'Duplicates: Run Schufa for all Completions where POA flag = Pending (regardless when it went to Pending)',
            'Rename customer data modification types',
            'Display Trxs/Second and Trxs/Minute in the System Status',
            'Show state reason for documents next to state',
            'Customer data modifications: deactivate POA documents with DistributorB2B if no POAs are submitted',
            'Settlement Export Service Retry Sftp Send if Sftp down',
        ],
    },
    {
        version: '0.31',
        date: 'July 2022',
        changes: [
            'Allow some special characters for file name upon document upload',
            'Alert for not received trx signature documents (after 24h)',
            'Document.Status -> Document.State + Document.Reason',
            'Identity Verification files - modified customers automatic upload',
            'ReKYC: Dynamics Case Template',
            'ReKYC: Add remark when accepting/rejecting review',
            'Decommission notification templates',
            'Restrict Limit/Limit account set action buttons',
            'Create CRM cases for address country not equal to DE',
            'DWH: update old Customers with a new KYC flag, rename schedulingReasons in old CustomerVerifications',
            'ReKYC: Solution for "old POAs"',
            'ReKYC: automatically accept documents and autocomplete if no critical data changed',
            'Export Transaction.CardId to DWH and show it in Admin',
            'Blocked -> Closed Customer State Transition',
            'Automatic Retry for failed Notifications due to tech issues',
            'CBM Export File Export Changes',
        ],
    },
    {
        version: '0.30',
        date: 'June 2022',
        changes: [
            'ReKYC: Include reject button in review pop up ',
            'ReKYC: Configure Notice Periods',
            'ReKYC: OverDue Verification -> Block Customer',
            'ReKYC: Allow user to complete event manually',
            'ReKYC: Handling of special characters (umlauts, ß, special characters from other alphabets like Scandinavian)',
            'ReKYC: Send Email notification for Customer Verifications in Data Rejected processing state',
            'Semi-Automate Duplicate merge scenario 2',
            'Duplicate merge scenario 2: automate middleName, maidenName, email, phone overwriting without review',
            'ID Document is rejected -> Tipico',
            'Send e-mail notification to blocked users',
            'Automatically delete inactive users after 30 days',
            'User Creation: Split name in first name and last name',
            'IdVerSvc: run GBG only if Firstname,Lastname or DOB changed after CustomerDataMerged, CustomerAccountMerged and CustomerUpdated',
            'Closed customers cannot be updated',
            'DWH: Extend CustomerFirstTransactionCount* reports with Type column',
            'Change document merge logic for POI',
            'Identity Verification files - modified customers automatic upload',
            'Reporting: Create CustomerVerifications DataSet',
            'Re-KYC - Dynamics Case Template',
        ],
    },
    {
        version: '0.29',
        date: 'May 2022',
        changes: [
            'Change Management in Backoffice Tool',
            'External Adhoc Customer Data Update',
            'Migrate Customer Merge/Review to Customer Verification Approach',
            'ReKYC: Drop validation if personal data is changed => document must be attached',
            'ReKYC: Additional Rejection Reasons',
            'User Admin - Send welcome email upon account creation',
            'Merchant Analyst role (Read + Write)',
            'Merchant Roles - only for specific merchant(s)',
            'Delete Users in Backoffice',
            'Audit trail for Users entity in BO',
            'Search Users by email',
        ],
    },
    {
        version: '0.28',
        date: 'April 2022',
        changes: [
            'C2D Backoffice Upgrade',
            'User Admin: Additional User attributes - Line Manager, Distributor/Merchant',
            'User Admin: Add details on password policy on the sign up/change password page',
            'CRA: Consider Reversal for High Deposit/Withdrawal risk rules',
            'CRA: Hide Risk Rule Parameter Overrides in the UI',
            'Customer Documents: currentAddressIncluded checkbox',
            'DWH: Not Received Documents Report',
            'DWH: CustomerRiskProfiles.RiskTier + View',
        ],
    },
    {
        version: '0.27',
        date: 'March 2022',
        changes: [
            'MS Planner → MS Dynamics CRM Migration',
            'Customer ReKYC (followup)',
            'Remove Phone Number from C2D Backoffice ',
            'Multiple Roles for Users in Admin UI for User Management',
            'CRA: Add risk rule - PEP self-declaration',
            'Adjust permission for Self-Declared PEP Flag',
            'Reversal of a reversal',
            'Tracking monthly records uploaded to OGM',
            'CurrentAddressIncluded for POI and SOFW documents',
        ],
    },
    {
        version: '0.26',
        date: 'February 2022',
        changes: [
            'MS Planner → MS Dynamics CRM Migration',
            'Customer ReKYC',
            'Activate Schufa for all new customer registrations (not activated)',
            'Add transaction error reasons ValidCustomerSOF/SOWRequired',
        ],
    },
    {
        version: '0.25',
        date: 'January 2022',
        changes: [
            'MS Planner → MS Dynamics CRM Migration',
            'New MS Dynamics CRM Queue - Data Quality Checks (incl. config per week day)',
            'Deactivate inactive BO users after 3 months',
            'Add Operator UUID in User tab',
        ],
    },
    {
        version: '0.24',
        date: 'December 2021',
        changes: [
            'SOF/SOW limits: migration for existing customers',
            'XML report for Central Bank of Malta',
            'TOTP: Select Auth Method and reset the choosen method for user by administrator',
            'Extend blocked and closed state reasons (rollout pending on Tipico)',
            'Allow change of document type (rollout pending on Tipico)',
            'User Roles Export from Backoffice',
        ],
    },
    {
        version: '0.23',
        date: 'November 2021',
        changes: [
            'New PosDevice.Type CashCenter',
            'Business Partner Portal: Show "contact us at billiing..." text below the list of files',
            'Business Partner Portal: Show filter distributor name',
            'Business Partner Portal: Remove distributorId from filenames in the table',
            'User Access Control: Automatically deactivate User accounts which have not logged in for 6 months',
            'User Access Control: Request to change password for active User accounts every 3 months',
            'Improve Customer Details -> Documents list',
            'Changes to SOF/SOW limits',
            'IdentityVerificationFile: modify customer selection',
            'Allow update Remark and customer details in parallel without collision',
            'Automatically date remarks',
            'DWH: Export additional Transaction details (CustomerPermissionDeniedReason, LimitThresholdExceededReason, MergedToCustomerId)',
        ],
    },
    {
        version: '0.22',
        date: 'October 2021',
        changes: [
            'Handle Customer Merge in CustRisk Svc',
            'Handle Customer Merge in Limit Svc',
            'Handle Customer Merge in TimeExp Svc',
            'Allow search by transaction amount or range',
            'Popup warning if merge is really to be done',
            'Reasons for rejection of POA/ID/SOF/SOW documents',
            'GBG Sftp Automatic Retries',
            'Internal Alert or MS Planner Ticket for failed IdVer GBG OGM Files (uploaded via SFTP)',
            'Disable Delay Booking for Merchant/Distributor Payment In/Out transactions',
            'Ms Planner Bucket: Skip ticket creation for Schufa triggered by Operator from Admin',
            'Add Failed Reason on "Failed Schufa Address Verification" MS Planner tickets',
            'Show Retry action only for technically failed Identity Verifications',
            'CRA: Changes geographical risk calculation',
            'CRA DWH Exports',
            'CRA Mass Recalculation Phase 1: Automatic Daily Recalculation',
            'CRA Mass Recalculation Phase 2: Backoffice UI',
            'DWH: Additional fields required for identity verifications',
            'Audit trail reporting - introduce another column PropertyNameNormalized',
        ],
    },
    {
        version: '0.21',
        date: 'September 2021',
        changes: [
            'Handle Customer Merge in CustRisk Svc',
            'Handle Customer Merge in Limit Svc',
            'Handle Customer Merge in TimeExp Svc',
            'Allow search by distributor name',
            'Automatically trigger schufa if POA docs rejected',
            'New distributor state transition PendingReview -> Blocked (OnboardingRejected)',
            'Allow PEP and Sanction screening KYC flags transition Not Matched -> Matched',
            'Exclude Admin actions from automatic ticket creation in MS Planner',
            'CRA: Import Geographic, High Risk and Blacklisted Countries from Clipboard',
            'CRA: Extend the Blacklisted Countries (only Iran) with MinDepositAmount 5k/past 30 days',
            'CRA: New alert for risk rule 24 deposit and immediate withdrawal',
            'DWH: Customer Merges Reporting',
        ],
    },
    {
        version: '0.20',
        date: 'August 2021',
        changes: [
            'New customer SOF/SOW flag transition',
            'Link Customers Upon Close/Duplicate',
            'Extend Transaction Service errors CustomerPermissionDenied, LimitAmount/TimeThresholdExceeded with reason/details',
            'Customer Merge Review Popup (MergedTo Customer)',
            'Handle Customer Merge in Card Svc',
            'CRA: Deposit/Withdrawal High Amount Realtime By Risk Tier Risk Rule',
            'CRA: Deposit and Immediate Withdrawal Risk Rule',
            'Allow to reverse transaction from Backoffice',
            'Merchant Account Balance Alert',
            'Auto hide toaster messages and message history',
        ],
    },
    {
        version: '0.19',
        date: 'July 2021',
        changes: [
            'Additional PepScreening=ReviewedPEPAllowed attributes',
            'Allowed transitions for SanctionsScreening KycFlag',
            'Business Partner Portal',
            'Document Number and IssuedBy Validation changes',
            'DWH: Export customer documents',
            'DWH: Export customer audit trail entries (without PII)',
            'KYC Document Analyst role',
            'Do not display accounting transaction entries if transaction rolled back',
        ],
    },
    {
        version: '0.18',
        date: 'June 2021',
        changes: [
            'Customer Nationality for Kosovo and Stateless',
            'Duplicate Customer Detection after registration',
            'Duplicate account manual review - allow single fields to be reviewed',
            'Dashboard (report) - further dissection by sub reason',
            'Customer Details: Display History of Remarks',
            'Delayed Account Balance',
        ],
    },
    {
        version: '0.17',
        date: 'May 2021',
        changes: [
            'Customer Risk Assessment',
            "Ms Planner: Manual Review of 'Other Proof of Identity' Documents",
            'Keep filters when moving between detailed view and list view',
            'DWH: Email and Phone number flag',
            'DWH: Add Schufa Scores to Identity Verification data export',
        ],
    },
    {
        version: '0.16',
        date: 'April 2021',
        changes: [
            'Registration validation: Reject if ID document is expired',
            'New document type: PEP Approval',
            'New document upload source: Customer Online',
            'New document status: Pending Review',
            'Change e-mail used to send our welcome e-mails',
            'Allow document metadata to be edited',
            'Manually change document status to Inactive Expired',
            'Audit Trail filtering by Event Type',
            'Allow filter by Name in distributor and merchant page',
            'Keep filters when moving between detailed view and list view',
            'New Blocked State sub-reason Pending Closure',
            'DWH: Export Identity Verification Details',
            'DWH: Export Card Service data to DWH',
            'DWH: Reporting on customer automatic duplicate merge',
        ],
    },
    {
        version: '0.15',
        date: 'March 2021',
        changes: [
            'MS Planner bucket for Failed Schufa Verifications',
            'Notification Details - show error code/message',
            'MS Planner task for not uploaded documents after 24h',
            'Increase Backoffice auto log out timeout',
            'Change in Permissions: Self-Declaration Manual Review',
            'Filter out Maiden Name if same as Last Name',
            'Split Dashboard into Realtime and Report Dashboard',
            'Optimize C2D Backoffice for mobile view',
        ],
    },
    {
        version: '0.14',
        date: 'February 2021',
        changes: [
            'Ms Planner queue for rejected docs by distributor',
            'Launch preparations (incl. Enable automatic send of welcome e-mail/sms for go-live, Activate negative merchant balance check for go live, Customer notification templates update)',
            'Schufa Integration',
            'Manual Schufa Verification',
            'POA KYC Flag update (in case Schufa and/or document provided)',
            'DWH: Expand Customers.StateReasons array field to fixed number of columns',
        ],
    },
    {
        version: '0.13',
        date: 'January 2021',
        changes: [
            'New Limit for SoF/SoW KYC Flags',
            'Rename Customer State KYCPending -> PendingKYC',
            'Update Email/SMS Templates for POA Customer Communication',
            'Add search filters in Transaction List View - Transaction Type',
            'backoffice.c2dpayment.com and backoffice.test.c2dpayment.com',
            'Update Invalid Duplicate Customer Merge document status logic',
            'Limits Audit Trail',
        ],
    },
    {
        version: '0.12',
        date: 'December 2020',
        changes: [
            'New e-mail / SMS templates related to Schufa',
            'Do not retry identity verification checks if the error is data format related',
            'Rename Identity Verification states',
            'Secure the 2 CRA-related TickBoxes on Customer Details Page',
            'Customers Search Filters: "normalisation" of characters for first/last name searches',
            'Support Different sender email for different customer notification templates',
            'Change Dashboard labels',
            'Remove document type = Car Registration',
            'Confirmation Dialog Box for re-sending notifications',
            'Remove scrollbar from Upload Document dialog',
            'Make customer middleName, maidenName, email, phoneNumber removable from admin',
        ],
    },
    {
        version: '0.11',
        date: 'November 2020',
        changes: [
            'Document Upload: allow user to set status (default Active, InActive(Inadmissible), InActive(InvalidDocumentType))',
            'Add one more flag status to MS Planner: GBG Screening Queue',
            'Update Back Office Roles',
            'Manual trigger of customer communication by template',
            'Add new e-mail / SMS templates (1B /2B)',
            'Customer documents - View Only feature',
            'Edit customer profile function should retain same distributor id + type Admin with operatorId',
            'Document Upload - Add addressIncluded attribute to some additional document types',
            'Document Upload - Change SOF/SOW flags only if they are in Pending state',
            'Add more flag statuses for Self-Declared PEP',
            'Add Merchant Account states - same as distributor',
        ],
    },
    {
        version: '0.10',
        date: 'October 2020',
        changes: [
            'Alert in case of missing daily export file to NAV',
            'Alert in case of missing weekly import file from NAV',
            'Tick Boxes (Material Adverse Media, STR Submitted) for CRA process',
            'Make DOB + Place of Birth editable',
            'New Role in Back Office - KYC Analyst',
            'Link from the customer profile to the notifications sent',
        ],
    },
    {
        version: '0.9',
        date: 'September 2020',
        changes: [
            'Customer document status (Accepted/Rejected)',
            'Filter Customers and Transactions by State (BUG: KYC Pending Customers in Dashboard not matching to Customers -> filter by Review Required)',
            'Manual Review Buckets in MS Planner: GBG Screening - Manual Review',
            'Manual Review Buckets in MS Planner: Self-Declared PEP - Manual Review',
            'Manual Review Buckets in MS Planner: Manual Document Review',
            'Manual Review Buckets in MS Planner: Duplicate Account Review Scenario 1',
            'Edit Role Merchant User - Dashboard + System Status pages',
            'Retry failed customer notification',
            'Create Mock GBG in Test',
            'BUG: Failed transactions are exported to MS Nav',
        ],
    },
    {
        version: '0.8',
        date: 'August 2020',
        changes: [
            '(General) Note field on Customer Details Page',
            'Upload Customer Documents in C2D Admin -> Customer Details Page',
            'Reopen Customer',
            'New Customer Document Type: WhitelistingUpdate',
            'New Customer Document Types: ScreeningResultSupportingMaterial, ManualReviewSupportingMaterial',
            'New Customer Document Type: AddressRegistration',
            'New validation rule: customer registration without T&C accepted = true is declined',
            'Bug: Upload IdVer File in C2D Admin with wrong period from/to',
            'Bug: C2D Admin -> Transaction details of trx without accounting entries should not show error loading acc entries',
            'Bug: C2D Admin -> Transaction details shows accounting entries from another trx',
        ],
    },
    {
        version: '0.7',
        date: 'July 2020',
        changes: [
            'Add new reason "Ad Hoc Review" for Blocked Status',
            'Customer Data Audit Log in C2D Admin',
            'Add Distributor states Pending/Blocked',
            'KYC Flag Updates: limit possible transitions',
        ],
    },
    {
        version: '0.6',
        date: 'June 2020',
        changes: [
            'KYC Flags and Customer States synchronization, manual actions like block, unblock, close',
            'GBG Realtime Customer Screening (after every individual customer registration) and Ongoing Monitoring (daily file export)',
            'Duplicate Account Check - manual review of the to-be-merged customer details like nationality, address, POA flag',
            'Customer Notification via email or sms after initial registration (link to C2D T&Cs)',
            'DWH extensions - additional column Transactions.AmountWithSign, new table Shops with unique values from Customers.RegisteredBy_ShopId and Transactions.ShopId',
            'Additional check whether card belongs to customer upon transaction PIN/TAN authentication and initial TAN setup validation',
        ],
    },
    {
        version: '0.5',
        date: 'May 2020',
        changes: [
            'Legal Age Check Validation (during Customer Registration)',
            'Filter customers by phone number (additional tab)',
            'Filter customers by first/lastname, date of birth changed to not require all 3 fields',
            'Customer Documents additional fields - scanned by, scanned on (datetime)',
            'Additional Customer Documents validation - document id, page, version, duplicates within a registration',
            'Added tooltip to download link for customer documents indicating document content (file) is available within 24h, not immediately (as it gets uploaded later in a batch)',
            'Realtime Business Dashboard',
            'Display SCA Whitelisted customer property on the Customer Details Page',
        ],
    },
    {
        version: '0.4',
        date: 'April 2020',
        changes: [
            'User Roles',
            'Bug: Transaction button in Customer View populates all transactions in the system',
            'Merchant and Distributor balance adjustments',
            'Allow reversals only on same business day (CET)',
            'Allow reversals only from Till',
            'Remove Sanctions self declaration attribute',
            'Add Response Times to the System Status Dashboard',
            'Add Declined Reason for transactions when Limits Exceeded or Insufficient Funds',
        ],
    },
    {
        version: '0.3',
        date: 'March 2020',
        changes: [
            'Document Upload - Customer Documents, Transaction Signatures',
            'SCA - Customer Cards with PIN and TAN Secret Key',
            'Reporting - DWH, Power BI, 4+ transaction monitoring reports',
            'C2D Admin - pre-fill time in filters',
        ],
    },
    {
        version: '0.2.1',
        date: 'January 2020',
        changes: ['System Availability/Status Dashboard', 'Release History (click on the verion number in the header)'],
    },
    {
        version: '0.2',
        date: 'December 2019',
        changes: [
            'Customer Personal Data Encryption (GDPR)',
            'Duplicate Customer Check',
            'Customer Permissions/KYC Flags Check during Transaction Processing',
            '2 weeks/2000 EUR limit if PoA is missing',
            'Settlement File for Navision',
        ],
    },
    {
        version: '0.1',
        date: 'October 2019',
        changes: [
            'Customer registration without Documents and KYC flags',
            'Transaction Processing (Passthrough Deposit, Passthrough Withdrawal) without limits',
            'Transaction Reversal Processing (Passthrough Deposit Reversal, Passthrough Withdrawal Reversal)',
            'C2D Admin',
        ],
    },
];
