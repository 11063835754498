import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';

export enum BadgeColor {
    Red,
    Default,
    Blue,
    Green,
    Purple,
    Teal,
    Magenta,
    Orange,
    Yellow,
}

export type BadgeProps = {
    text: string;
    color: BadgeColor;
    inline?: boolean;
};

export const Badge: React.FC<BadgeProps> = (props) => {
    const classes = useStyles();

    return (
        <span
            className={mergeClasses(
                classes.root,
                props.inline && classes.inline,
                props.color === BadgeColor.Blue && classes.blue,
                props.color === BadgeColor.Green && classes.green,
                props.color === BadgeColor.Magenta && classes.magenta,
                props.color === BadgeColor.Orange && classes.orange,
                props.color === BadgeColor.Purple && classes.purple,
                props.color === BadgeColor.Red && classes.red,
                props.color === BadgeColor.Teal && classes.teal,
                props.color === BadgeColor.Yellow && classes.yellow,
            )}
        >
            {props.text}
        </span>
    );
};

const useStyles = makeStyles({
    root: {
        borderRadius: '4px',
        padding: '2px 6px',
        margin: '-2px 0',
        fontSize: tokens.fontSizeBase200,
        fontFamily: tokens.fontFamilyBase,
        lineHeight: tokens.fontSizeBase400,
        boxSizing: 'border-box',
        display: 'inline-block',
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorNeutralForeground4,
    },
    inline: {
        fontSize: 'inherit',
    },
    red: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorStatusDangerBackground3,
    },
    blue: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorPaletteBlueForeground2,
    },
    purple: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorPaletteLilacForeground2,
    },
    teal: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorPaletteLightTealForeground2,
    },
    magenta: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorPaletteMagentaForeground2,
    },
    orange: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorStatusWarningBackground3,
    },
    yellow: {
        color: tokens.colorNeutralForeground1,
        backgroundColor: tokens.colorPaletteYellowBackground3,
    },
    green: {
        color: tokens.colorNeutralForegroundInverted,
        backgroundColor: tokens.colorStatusSuccessBackground3,
    },
});
