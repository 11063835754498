import { useEffect } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { useLocation } from 'react-router-dom';

const historyLength = 50;

type PageHistory = {
    length: number;
    previous: string[];
};

type PageHistoryState = {
    history: PageHistory;
};

const initialState: PageHistoryState = { history: { previous: [], length: 0 } };

const { useGlobalState } = createGlobalState(initialState);

export const usePageHistory = () => {
    const [state, setState] = useGlobalState('history');
    const location = useLocation();

    useEffect(() => {
        setState(({ length, previous }) => {
            const last = previous[previous.length - 1];

            if (last === location.pathname) {
                return { length, previous };
            }

            return {
                length: length + 1,
                previous: [...previous.slice(1 - historyLength), location.pathname],
            };
        });
    }, [location.pathname, setState]);

    return state;
};
