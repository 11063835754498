import { makeStyles, Overflow, OverflowItem, Tab, TabList, TabValue } from '@fluentui/react-components';
import { Children, isValidElement, PropsWithChildren, useState } from 'react';

import { FilterProps } from './Filter';
import { FilterOverflowMenu } from './FilterOverflowMenu';

export type FilterListProps = {
    className?: string;
    defaultFilter?: string;
    onChange?: (filter: TabValue) => void;
};

export const FilterList: React.FC<PropsWithChildren<FilterListProps>> = (props) => {
    const classes = useStyles();

    const children = Children.map(props.children, (child) => {
        if (isValidElement<PropsWithChildren<FilterProps>>(child)) {
            return child.props;
        } else if (child) {
            throw new Error('Only FilterItem children allowed');
        }
    })?.filter((child) => !!child);

    const [tab, setTab] = useState<TabValue>(props.defaultFilter ?? children?.[0].name ?? '');

    const changeTab = (value: TabValue) => {
        setTab(value);

        props.onChange?.(value);
    };

    return (
        <div className={props.className}>
            {children && (
                <Overflow minimumVisible={1}>
                    <TabList className={classes.bar} selectedValue={tab} onTabSelect={(event, tab) => changeTab(tab.value)}>
                        {children.map((child) => (
                            <OverflowItem key={child.name} id={child.name} priority={child.name === tab ? 2 : 1}>
                                <Tab value={child.name} className={classes.tab} content={{ className: classes.tabContent }}>
                                    {child.title}
                                </Tab>
                            </OverflowItem>
                        ))}
                        <FilterOverflowMenu filters={children} onClick={(filter) => changeTab(filter.name)} />
                    </TabList>
                </Overflow>
            )}

            {children?.find((child) => child.name === tab)?.children}
        </div>
    );
};

const useStyles = makeStyles({
    bar: {
        margin: '0 -10px',
    },
    tab: {
        overflow: 'hidden',
        textWrap: 'nowrap',
    },
    tabContent: {
        textOverflow: 'ellipsis',
    },
});
