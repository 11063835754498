// eslint-disable-next-line no-restricted-imports
import { Link as FuiLink, LinkProps } from '@fluentui/react-components';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

export const Link: React.FC<LinkProps> = (props) => {
    const navigate = useNavigate();

    const onClick = (event: MouseEvent<HTMLAnchorElement> & MouseEvent<HTMLButtonElement>) => {
        if ('href' in props && props.href) {
            navigate(props.href);
            event.preventDefault();
        }

        props.onClick?.(event);
    };

    return <FuiLink onClick={onClick} {...props} />;
};
