import { Button, DrawerBody, DrawerHeader, makeStyles, OverlayDrawer, tokens } from '@fluentui/react-components';
import { Dismiss24Regular, MoreVertical24Regular } from '@fluentui/react-icons';
import { useState } from 'react';

import { useAccount } from '../hooks';
import { HeaderButton } from './HeaderButton';
import { LeftMenu } from './LeftMenu';
import { UserFlyoutMenu } from './UserFlyoutMenu';

export const FlyoutMenu: React.FC = () => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const { account } = useAccount();

    if (!account) {
        return null;
    }

    return (
        <>
            <HeaderButton onClick={() => setOpen(true)}>
                <MoreVertical24Regular className={classes.icon} />
            </HeaderButton>
            <OverlayDrawer position="end" size="full" open={open} onOpenChange={(_, { open }) => setOpen(open)}>
                <DrawerHeader>
                    <Button appearance="subtle" className={classes.close} icon={<Dismiss24Regular />} onClick={() => setOpen(false)} />
                    <UserFlyoutMenu account={account} preview />
                </DrawerHeader>

                <DrawerBody>
                    <LeftMenu onLinkClick={() => setOpen(false)} />
                </DrawerBody>
            </OverlayDrawer>
        </>
    );
};

const useStyles = makeStyles({
    close: {
        position: 'absolute',
        top: tokens.spacingVerticalXL,
        right: tokens.spacingHorizontalXL,
    },
    icon: {
        color: tokens.colorNeutralForegroundInverted,
        cursor: 'pointer',
        opacity: 0.65,
    },
});
