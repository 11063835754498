import { Button, makeStyles, tokens } from '@fluentui/react-components';
import { ArrowReset24Regular, Search24Regular } from '@fluentui/react-icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export type FilterFormProps = {
    onSubmit?: () => void;
    onClear?: () => void;
};

export const FilterForm: React.FC<React.PropsWithChildren<FilterFormProps>> = (props) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const [resetCount, setResetCount] = useState(0);

    // the useForm hook needs to first flush the reset and then trigger the submit
    // so we wire up the submit after the reset through an effect
    useEffect(() => {
        if (resetCount) {
            props.onSubmit?.();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetCount]);

    const clear = () => {
        props.onClear?.();
        setResetCount((count) => count + 1);
    };

    const keyboardSubmit = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Enter') {
            props.onSubmit?.();
        }
    };

    return (
        <section className={classes.root} onKeyUp={keyboardSubmit}>
            {props.children}
            <footer className={classes.actions}>
                {props.onSubmit && (
                    <Button appearance="primary" icon={<Search24Regular />} onClick={props.onSubmit}>
                        {t('search')}
                    </Button>
                )}
                {props.onClear && (
                    <Button icon={<ArrowReset24Regular />} onClick={clear}>
                        {t('clear')}
                    </Button>
                )}
            </footer>
        </section>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'grid',
        paddingTop: tokens.spacingVerticalM,
        gridTemplateColumns: 'repeat(auto-fill, minmax(240px, 1fr))',
        alignItems: 'start',
        gap: tokens.spacingHorizontalL,
    },
    actions: {
        display: 'flex',
        gridColumnStart: '1',
        gridColumnEnd: '-1',
        gap: tokens.spacingHorizontalS,
    },
});
