import { Children, isValidElement, ReactElement, ReactNode } from 'react';
import { Navigate, Route, RouteProps, Routes } from 'react-router-dom';

const isValidRoute = (route: ReactNode): route is ReactElement<RouteProps> => isValidElement(route) && route.type === Route;

const getValidRoute = (children: ReactNode): string | undefined => {
    const routes = Children.toArray(children);

    const route = routes.find(isValidRoute);

    if (route?.props.children) {
        return getValidRoute(route.props.children);
    }

    let path = route?.props.path;

    if (path?.endsWith('/*')) {
        path = path.slice(0, -2);
    }

    if (path?.startsWith('/')) {
        path = path.slice(1);
    }

    if (path !== 'admin' && path !== '*' && path !== '') {
        return path;
    }
};

export const FallbackRoutes: React.FC<RouteProps> = (props) => {
    const fallback = getValidRoute(props.children);

    return (
        <Routes>
            {fallback && <Route path="" element={<Navigate to={fallback} />} />}
            {props.children}
        </Routes>
    );
};
