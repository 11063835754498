import { Dropdown, Field, makeStyles, mergeClasses, Option, OptionGroup, Select, SelectProps } from '@fluentui/react-components';
import { useMemo } from 'react';
import { Controller } from 'react-hook-form';

import { mobile, validate } from './helpers';
import { ControllerFieldProps } from './models';

export type SelectOption = {
    disabled?: boolean;
    group?: string;
    key: any;
    text: string;
};

export type SelectFieldProps = Omit<SelectProps, 'multiple' | 'children' | 'select'> & {
    label?: string;
    select?: { className?: string };
    options: SelectOption[];
};

export const SelectField: React.FC<SelectFieldProps & ControllerFieldProps> = ({ control, rules, label, className, options, ...props }) => {
    const classes = useStyles();

    const groups = useMemo(() => {
        const grouped = options.reduce<Record<string, SelectOption[]>>((acc, option) => {
            const group = option.group ?? '';

            acc[group] ??= [];
            acc[group].push(option);

            return acc;
        }, {});

        return Object.entries(grouped)
            .filter(([_, v]) => v?.length)
            .map(([k, v]) => [k === '' ? undefined : k, v]) as [string | undefined, SelectOption[]][];
    }, [options]);

    return (
        <Controller
            defaultValue={props.defaultValue}
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field label={label} {...validate(fieldState)} className={className}>
                    {mobile ? (
                        <Select
                            {...props}
                            {...field}
                            className={mergeClasses(classes.wrapper, props.select?.className)}
                            select={{ className: mergeClasses(classes.select, props.select?.className) }}
                        >
                            {groups.map(([group, options], index) => (
                                <optgroup key={group || index} label={group || undefined}>
                                    {options.map((option, index) => (
                                        <option key={option.key ?? index} value={option.key} disabled={option.disabled}>
                                            {option.text}
                                        </option>
                                    ))}
                                </optgroup>
                            ))}
                        </Select>
                    ) : (
                        <Dropdown
                            disabled={props.disabled}
                            className={mergeClasses(classes.dropdown, props.select?.className)}
                            value={options.find((option) => option.key === field.value)?.text ?? field.value ?? ''}
                            onOptionSelect={(_, data) => field.onChange(data.optionValue)}
                            onBlur={field.onBlur}
                            selectedOptions={[field.value]}
                        >
                            {groups.map(([group, options], index) => (
                                <OptionGroup key={group || index} label={group}>
                                    {options.map((option, index) => (
                                        <Option key={option.key ?? index} value={option.key} text={option.text} disabled={option.disabled}>
                                            {option.text}
                                        </Option>
                                    ))}
                                </OptionGroup>
                            ))}
                        </Dropdown>
                    )}
                </Field>
            )}
        />
    );
};

const useStyles = makeStyles({
    wrapper: {
        overflow: 'hidden',
    },
    dropdown: {
        minWidth: 'unset',
    },
    select: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
});
