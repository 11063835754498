import { isValid } from 'date-fns';

import { Distributor } from '../distributors';
import { Merchant } from '../merchants';
import { Shop } from '../shops';

export type Required<T> = {
    [P in keyof T]-?: T[P];
};

export type PosDevice = {
    type: string;
    appInstallationId?: string;
    id: string;
};

export enum TriggerType {
    Admin = 'Admin',
    DistributorB2B = 'DistributorB2B',
    System = 'System',
    CustomerOnline = 'CustomerOnline',
    PosApp = 'PosApp',
    MerchantB2B = 'MerchantB2B',
    CustomerWebApp = 'CustomerWebApp',
    CustomerPortal = 'CustomerPortal',
}

export type TriggerSource = {
    type: TriggerType;
    distributorId?: string;
    distributor?: Distributor;
    shopId?: string;
    shop?: Shop;
    posDevice?: PosDevice;
    operatorId?: string;
    merchantId?: string;
    merchant?: Merchant;
    customerId?: string;
};

export type Event<T> = {
    eventId: string;
    createdOn: string;
    publishedOn: string;
    eventType: string;
    refEntityId: string;
    data: T;
};

export type EntityWithEtag = {
    etag?: string;
};

export type List<T> = {
    items?: T[];
};

export type PagedList<T> = {
    items: T[];
    next?: T;
};

export type ListFilter = {
    from?: Date;
    to?: Date;
    limit?: number;
    type?: string;
    page?: number;
};

export function getDateField(item: any, field: string): Date | undefined {
    if (item) {
        const fromField = item[field];

        if (typeof fromField === 'string') {
            const parsed = new Date(fromField);

            if (isValid(parsed)) {
                return parsed;
            }
        }

        if (fromField instanceof Date && isValid(fromField)) {
            return fromField;
        }
    }
}

export function updateFilters<TList, TKey extends keyof TList, TFilter extends ListFilter | undefined>(
    newPage: number,
    content: PagedList<TList> | undefined,
    filters?: TFilter,
    field?: TList[TKey] extends Date ? TKey : TList[TKey] extends string ? TKey : never,
) {
    if (filters) {
        const currentPage = filters.page ?? 1;
        const isNext = newPage > currentPage;
        const isPrevious = newPage < currentPage;

        if (isNext && content?.next) {
            const to = getDateField(content.next, (field as string) ?? 'createdOn');
            return { ...filters, to, from: undefined, page: newPage };
        } else if (isPrevious && content?.items?.length) {
            const from = getDateField(content.items[0], (field as string) ?? 'createdOn');
            return { ...filters, to: undefined, from, page: newPage };
        } else {
            return filters;
        }
    }
}
export enum Currency {
    EUR = 'EUR',
}

export enum Role {
    ApiIntegrationUser = 'APIIntegrationUser',
    ComplianceOfficer = 'ComplianceOfficer',
    DashboardUser = 'DashboardUser',
    DistributorUser = 'DistributorUser',
    FinanceOfficer = 'FinanceOfficer',
    MerchantAnalyst = 'MerchantAnalystRead_MerchantAnalystWrite',
    MerchantAnalystReadonly = 'MerchantAnalystRead',
    MerchantUser = 'MerchantUser',
    OperationsAnalyst = 'OperationAnalystRead_OperationAnalystWrite',
    OperationsAnalystReadonly = 'OperationAnalystRead',
    OperationsManager = 'OperationAnalystRead_OperationAnalystWrite_OperationManager',
    TechnicalUser = 'TechnicalUser',
    UserAdmin = 'UserAdmin',
    ChangeManager = 'ChangeManager',
    ShopOperator = 'ShopOperator',
    PosTester = 'PosTester',
    AppStoreReviewer = 'AppStoreReviewer',
    DistributorManager = 'DistributorManager',
    AccountManagementCoordinator = 'AccountManagementCoordinator',
}

export type TriggerSourceAdmin = {
    type: string;
    operatorId: string;
};

export function getTriggerSourceAdmin(operatorId: string): TriggerSourceAdmin {
    return {
        type: TriggerType.Admin,
        operatorId,
    };
}
