import React from 'react';

export type LogoProps = React.SVGProps<SVGSVGElement> & {
    fill?: string;
};

export const Logo: React.FC<LogoProps> = ({ fill, ...props }) => {
    const textColor = '#4C4B63';
    const accentColor = '#C8102E';

    return (
        <svg viewBox="0 0 140 81" {...props}>
            <g>
                <path
                    d="M138.973 68.889c0 6.21-5.04 11.25-11.25 11.25s-11.25-5.04-11.25-11.25 5.04-11.25 11.25-11.25 11.25 5.04 11.25 11.25"
                    fill={fill ?? textColor}
                />
                <path
                    d="M110.593 51.979h24.52s-13.51-40.78-16.04-49.09c-.42-1.39-1.41-2.11-3.09-2.11-4.78.03-12.45.04-17.57 0-1.63-.02-3.24.53-4.09 1.77-7.82 11.52-31.79 49.42-31.79 49.42h26.37l13.82-22.01 7.85 22.03.02-.01z"
                    fill={fill ?? textColor}
                />
                <path
                    d="M84.733 59.939l-12.02 19.55-69.23.05c-2.1 0-3.33-2.35-2.14-4.08l38.04-55.08h-28.88l3.07-16.73c.3-1.64 1.73-2.82 3.39-2.82h51.15l5.31-.04c2.02-.01 3.25 2.23 2.14 3.92l-36.14 55.28 45.33-.05h-.02z"
                    fill={fill ?? accentColor}
                />
            </g>
        </svg>
    );
};
