import { Menu, MenuItem, MenuList, MenuPopover, MenuTrigger } from '@fluentui/react-components';
import { GlobeRegular } from '@fluentui/react-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { defaultLanguage, supportedLanguages } from '../config';
import { HeaderButton } from './HeaderButton';

export const LanguageMenu: React.FC = () => {
    const { i18n } = useTranslation();

    const [language, setLanguage] = useState(
        supportedLanguages.find((current) => current.code === i18n.resolvedLanguage) ?? defaultLanguage,
    );

    const changeLanguage = (code: string) => {
        const language = supportedLanguages.find((current) => current.code === code);

        if (language) {
            setLanguage(language);
            i18n.changeLanguage(language.code);
        }
    };
    return (
        <Menu positioning="below-end">
            <MenuTrigger disableButtonEnhancement>
                <HeaderButton icon={<GlobeRegular />}>{language.code.toUpperCase()}</HeaderButton>
            </MenuTrigger>

            <MenuPopover>
                <MenuList>
                    {supportedLanguages.map((language) => (
                        <MenuItem key={language.code} onClick={() => changeLanguage(language.code)}>
                            {language.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </MenuPopover>
        </Menu>
    );
};
