import { Avatar, Popover, PopoverSurface, PopoverTrigger } from '@fluentui/react-components';

import { useAccount } from '../hooks';
import { HeaderButton } from './HeaderButton';
import { UserFlyoutMenu } from './UserFlyoutMenu';

export const UserMenu: React.FC = () => {
    const { account } = useAccount();

    if (!account) {
        return null;
    }

    return (
        <Popover>
            <PopoverTrigger disableButtonEnhancement>
                <HeaderButton>
                    <Avatar name={account.name} size={40} />
                </HeaderButton>
            </PopoverTrigger>

            <PopoverSurface tabIndex={-1}>
                <UserFlyoutMenu account={account} />
            </PopoverSurface>
        </Popover>
    );
};
