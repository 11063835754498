import { Button, MessageBar, MessageBarActions, MessageBarBody, MessageBarTitle } from '@fluentui/react-components';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { ErrorType } from './error';

export type ErrorDescription = {
    title: string;
    message: string;
};

export type ErrorMessageProps = {
    errorType?: ErrorType;
    overrideDescription?: Partial<Record<ErrorType, ErrorDescription>>;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({ errorType = 'general', overrideDescription: override }: ErrorMessageProps) => {
    const { t } = useTranslation('common');

    const error = override?.[errorType] ?? {
        title: t('error.title', { context: errorType }),
        message: t('error.message', { context: errorType }),
    };

    return (
        <MessageBar intent="error">
            <MessageBarBody>
                <MessageBarTitle>{error.title}</MessageBarTitle> {error.message}
            </MessageBarBody>
            <MessageBarActions>
                <Button onClick={() => globalThis.location.reload()}>{t('refresh')}</Button>
            </MessageBarActions>
        </MessageBar>
    );
};
