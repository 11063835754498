import { makeStyles, mergeClasses, Title3, tokens } from '@fluentui/react-components';
import { QuestionCircleRegular, SlashForwardFilled } from '@fluentui/react-icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Stack } from '../common';
import { featureToggles } from '../config';
import { usePageTitle } from '../hooks';
import { FlyoutMenu } from './FlyoutMenu';
import { HeaderButton } from './HeaderButton';
import { LanguageMenu } from './LanguageMenu';
import { MessageHistoryMenu } from './MessageHistoryMenu';
import { UserMenu } from './UserMenu';

export type HeaderProps = {
    className?: string;
    responsive: boolean;
};

export const Header: React.FC<HeaderProps> = (props) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const { t } = useTranslation('common');

    const [title] = usePageTitle();

    const { mainTitle, subTitle } = useMemo(() => {
        if (title?.includes(' | ')) {
            const [mainTitle, subTitle] = title.split(' | ');

            return { mainTitle, subTitle };
        }

        return { mainTitle: title };
    }, [title]);

    return (
        <section className={mergeClasses(classes.root, props.className)}>
            <Stack align="center" spacing="none" horizontal className={classes.titleContainer}>
                <Title3 className={classes.title}>{mainTitle}</Title3>
                {subTitle && (
                    <>
                        <SlashForwardFilled className={classes.titleSpacer} />
                        <Title3 className={classes.subTitle}> {subTitle}</Title3>
                    </>
                )}
            </Stack>
            {props.responsive ? (
                <FlyoutMenu />
            ) : (
                <menu className={classes.menu}>
                    {featureToggles.helpEnabled && (
                        <HeaderButton icon={<QuestionCircleRegular />} onClick={() => navigate('/help')}>
                            {t('menu.help')}
                        </HeaderButton>
                    )}
                    <LanguageMenu />
                    <MessageHistoryMenu />
                    <UserMenu />
                </menu>
            )}
        </section>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        boxShadow: tokens.shadow16,
        backgroundColor: tokens.colorBrandBackgroundHover,
        alignItems: 'center',
        justifyContent: 'space-between',
        height: '48px',
        padding: `${tokens.spacingVerticalSNudge} ${tokens.spacingHorizontalSNudge} ${tokens.spacingVerticalSNudge} ${tokens.spacingHorizontalL}`,
        gap: tokens.spacingVerticalSNudge,
        '@media print': {
            boxShadow: 'unset',
            paddingLeft: 0,
        },
    },
    menu: {
        display: 'flex',
        alignItems: 'center',
        gap: tokens.spacingHorizontalS,
    },
    titleContainer: {
        overflow: 'hidden',
    },
    title: {
        color: tokens.colorNeutralForegroundInverted,
        opacity: 0.7,
        lineHeight: 'unset',
        '@media print': {
            color: tokens.colorNeutralForeground1,
        },
    },
    titleSpacer: {
        color: tokens.colorNeutralForegroundInverted,
        opacity: 0.5,
        fontSize: tokens.fontSizeHero800,
    },
    subTitle: {
        color: tokens.colorNeutralForegroundInverted,
        opacity: 0.5,
        lineHeight: 'unset',
        '@media print': {
            color: tokens.colorNeutralForeground1,
        },
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        minWidth: 0,
    },
});
