import { SetStateAction } from 'react';
import { createGlobalState } from 'react-hooks-global-state';

type StaleDataState = {
    staleData: boolean;
};

const initialState: StaleDataState = { staleData: false };

const { useGlobalState, setGlobalState } = createGlobalState(initialState);

export const useStaleData = () => useGlobalState('staleData');

export const setStaleData = (staleData: SetStateAction<boolean>) => setGlobalState('staleData', staleData);
