import { defaultLanguage, supportedLanguages } from '../config';
import { Currency } from './models';

const browserLanguage = window.navigator.language;
const language = supportedLanguages.find((language) => language.locale === browserLanguage) ?? defaultLanguage;

export type CurrencyFormat = 'currency' | 'sign';

export const formatCurrency = (value: number | undefined, currency: Currency | undefined, format?: CurrencyFormat) => {
    if (value === null || value === undefined) {
        return;
    }

    let style: Intl.NumberFormatOptionsStyle | undefined = undefined;

    if (format === 'sign') {
        style = 'currency';
    }

    const formattedValue = Number(value).toLocaleString(language.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        currency,
        style,
    });

    if (format === 'sign') {
        return formattedValue;
    }

    return `${formattedValue} ${currency}`.trim();
};

export type FormattedCurrencyProps = {
    value?: number;
    currency?: Currency;
    format?: CurrencyFormat;
};

export const FormattedCurrency: React.FC<FormattedCurrencyProps> = (props) => {
    return <>{formatCurrency(props.value, props.currency, props.format)}</>;
};
