import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { PropsWithChildren, useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';

export const TranslationProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        i18n.use(initReactI18next) // passes i18n down to react-i18next
            .use(Backend)
            .use(LanguageDetector)
            .init({
                debug: import.meta.env.DEV,
                load: 'languageOnly',
                ns: ['common'],
                defaultNS: 'common',
                fallbackLng: 'en',
                interpolation: {
                    escapeValue: false, // react already safes from xss
                },
                backend: {
                    loadPath: '/locales/{{lng}}/{{ns}}.json',
                },
            })
            .then(() => setInitialized(true));
    }, []);

    if (initialized) {
        return <>{children}</>;
    }

    return null;
};
