import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ErrorMessage } from '../common/ErrorMessage';

type ErrorFallbackProps = {
    error?: Error;
    reset?: () => void;
};

export const ErrorFallback: React.FC<ErrorFallbackProps> = (props) => {
    const location = useLocation();
    const [initialLocation] = useState(location);

    useEffect(() => {
        if (location.key !== initialLocation.key) {
            props.reset?.();
        }
    }, [location, initialLocation, props]);

    return <ErrorMessage errorType="general" />;
};
