import {
    makeStyles,
    mergeClasses,
    Skeleton,
    SkeletonItem,
    Table,
    TableBody,
    TableCell,
    TableCellLayout,
    TableHeader,
    TableHeaderCell,
    TableRow,
    tokens,
} from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { TableColumn } from './ListView';

export type ListViewTableProps<T = any> = {
    items?: T[];
    loading?: boolean;
    config: TableColumn<T>[];
};

const skeletons = Array.from(Array(10));

export const ListViewTable: React.FC<ListViewTableProps> = ({ config, loading, items }) => {
    const classes = useStyles();

    const { t } = useTranslation('common');

    const paddingClasses = (column: TableColumn) => {
        const first = config[0] === column;
        const last = config[config.length - 1] === column;

        if (column.type === 'content') {
            return mergeClasses(first && classes.firstCell, last && classes.lastCell);
        }
    };

    const alignmentClasses = (column: TableColumn) => {
        switch (column.align) {
            case 'center':
                return classes.alignCenter;
            case 'right':
                return classes.alignRight;
        }
    };

    const empty = loading ? false : !items || !items.length;

    return (
        <Table className={classes.root}>
            <TableHeader>
                <TableRow>
                    {config.map((column, index) => (
                        <TableHeaderCell
                            key={index}
                            className={mergeClasses(
                                classes.headerCell,
                                column.type === 'action' && classes.actionCell,
                                paddingClasses(column),
                                alignmentClasses(column),
                            )}
                        >
                            {column.label}
                        </TableHeaderCell>
                    ))}
                </TableRow>
            </TableHeader>

            {loading && (
                <TableBody>
                    {skeletons.map((_, index) => (
                        <TableRow key={index} className={mergeClasses(index === skeletons.length - 1 && classes.lastRow)}>
                            {config.map((column, index) => (
                                <TableCell
                                    key={index}
                                    className={mergeClasses(classes.cell, paddingClasses(column), alignmentClasses(column))}
                                >
                                    <Skeleton>
                                        <SkeletonItem />
                                    </Skeleton>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            )}

            {items && (
                <TableBody>
                    {items.map((item, index) => (
                        <TableRow key={index} className={mergeClasses(index === items.length - 1 && classes.lastRow)}>
                            {config.map((column, index) => (
                                <TableCell
                                    key={index}
                                    className={mergeClasses(classes.cell, paddingClasses(column), alignmentClasses(column))}
                                >
                                    {column.type === 'action' ? (
                                        <TableCellLayout>{column.onRender(item)}</TableCellLayout>
                                    ) : (
                                        column.onRender(item)
                                    )}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </TableBody>
            )}

            {empty && (
                <TableBody>
                    <TableRow className={mergeClasses(classes.lastRow)}>
                        <TableCell colSpan={config.length} className={mergeClasses(classes.cell, classes.alignCenter, classes.emptyCell)}>
                            {t('noData')}
                        </TableCell>
                    </TableRow>
                </TableBody>
            )}
        </Table>
    );
};

const useStyles = makeStyles({
    root: {
        tableLayout: 'auto',
    },
    cell: {
        height: '36px',
        paddingTop: tokens.spacingVerticalXS,
        paddingBottom: tokens.spacingVerticalXS,
    },
    actionCell: {
        width: '1%',
        minWidth: '20px',
        paddingTop: 0,
        paddingBottom: 0,
    },
    emptyCell: {
        backgroundColor: tokens.colorNeutralBackground5,
        padding: `${tokens.spacingVerticalM} ${tokens.spacingHorizontalXL}`,
    },
    firstCell: {
        paddingLeft: tokens.spacingHorizontalXL,
    },
    lastCell: {
        paddingRight: tokens.spacingHorizontalXL,
    },
    headerCell: {
        fontWeight: tokens.fontWeightSemibold,
        paddingTop: tokens.spacingVerticalS,
        paddingBottom: tokens.spacingVerticalS,
        whiteSpace: 'nowrap',
    },
    lastRow: {
        borderBottomWidth: '0',
    },
    alignCenter: {
        textAlign: 'center',
    },
    alignRight: {
        textAlign: 'right',
        '> [role="presentation"]': {
            justifyContent: 'end',
        },
    },
});
