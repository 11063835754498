import {
    Button,
    Caption1,
    makeStyles,
    Toast,
    ToastBody,
    Toaster,
    ToastFooter,
    ToastTitle,
    ToastTrigger,
    useId,
    useToastController,
} from '@fluentui/react-components';
import { bundleIcon, Dismiss20Filled, Dismiss20Regular } from '@fluentui/react-icons';
import { useEffect } from 'react';

import { FormattedDate } from '../common';
import { usePageMessages } from '../hooks';

const Dismiss = bundleIcon(Dismiss20Filled, Dismiss20Regular);

export const MessageToaster: React.FC = () => {
    const classes = useStyles();
    const { messages } = usePageMessages();

    const toasterId = useId('toaster');
    const { dispatchToast } = useToastController(toasterId);

    useEffect(() => {
        const last = messages[0];

        if (last) {
            dispatchToast(
                <Toast>
                    <ToastTitle
                        action={
                            <ToastTrigger>
                                <Button className={classes.dismiss} appearance="transparent" icon={<Dismiss />} />
                            </ToastTrigger>
                        }
                    >
                        {last.message}
                    </ToastTitle>
                    {last.details && <ToastBody>{last.details}</ToastBody>}
                    <ToastFooter>
                        <Caption1>
                            <FormattedDate format="full-date" value={last.time} />
                        </Caption1>
                    </ToastFooter>
                </Toast>,
                { intent: last.type, timeout: 10_000, position: 'top-end' },
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messages]);

    return <Toaster toasterId={toasterId} offset={{ vertical: 60 }} />;
};

const useStyles = makeStyles({
    dismiss: {
        padding: 0,
        minWidth: 0,
    },
});
