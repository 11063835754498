import axios from 'axios';
import qs from 'qs';

import { getResponse } from '../common';
import { endpoints } from '../config';
import { RefreshToken, RefreshTokenWithAccessToken } from './models';

const SERVICE_PREFIX = 'refresh-tokens';
const CMD_SERVICE_URL = `${endpoints.authSvcCmdHUrl}/${SERVICE_PREFIX}`;

const emergencyAuthApi = axios.create({
    paramsSerializer: {
        serialize: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
    },
});

export function generate(id: string, accessToken: string) {
    return emergencyAuthApi
        .put<RefreshToken>(`${CMD_SERVICE_URL}/${id}`, '', {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                Accept: `application/json`,
            },
        })
        .then(getResponse);
}

export function redeem(data: RefreshToken) {
    return emergencyAuthApi.post<RefreshTokenWithAccessToken>(`${CMD_SERVICE_URL}/${data.id}/redeem`, data).then(getResponse);
}
