import { Body1, makeStyles, mergeClasses, Skeleton, SkeletonItem, Subtitle2, tokens } from '@fluentui/react-components';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ResponsiveColumn } from './ListView';
import { Stack } from './Stack';

export type ListViewCardProps<T = any> = {
    items?: T[];
    loading?: boolean;
    config: ResponsiveColumn<T>;
};

const skeletons = Array.from(Array(10));

export const ListViewCard: React.FC<ListViewCardProps> = ({ config, loading, items }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    const { t } = useTranslation('common');

    const empty = loading ? false : !items || !items.length;

    const itemClasses = (index: number) => {
        return mergeClasses(classes.item, index > 0 && classes.bordered);
    };

    return (
        <Stack spacing="none" justify="stretch">
            {loading &&
                skeletons.map((_, index) => (
                    <Skeleton key={index} className={itemClasses(index)}>
                        <Stack spacing="small" className={classes.skeleton}>
                            <SkeletonItem size={20} />
                            <SkeletonItem />
                            <SkeletonItem />
                        </Stack>
                    </Skeleton>
                ))}

            {items?.map((item, index) => {
                const { header, content, footer, href, onClick } = config.onRender(item);

                const onAction = ({ target }: MouseEvent<HTMLDivElement>) => {
                    if (target instanceof HTMLElement && target.onclick && !target.attributes.getNamedItem('data-item')) {
                        return;
                    }

                    onClick?.();

                    if (href) {
                        navigate(href);
                    }
                };

                return (
                    <div data-item key={index} className={classes.container} onClick={onAction}>
                        <Stack spacing="tiny" className={itemClasses(index)}>
                            {header && <Subtitle2>{header}</Subtitle2>}
                            {content && <Body1>{content}</Body1>}
                            <Body1>{footer}</Body1>
                        </Stack>
                    </div>
                );
            })}

            {empty && <Stack className={classes.empty}>{t('noData')}</Stack>}
        </Stack>
    );
};

const useStyles = makeStyles({
    container: {
        ':hover': {
            cursor: 'pointer',
            backgroundColor: tokens.colorNeutralBackground1Hover,
        },
        ':active': {
            cursor: 'pointer',
            backgroundColor: tokens.colorNeutralBackground1Pressed,
        },
    },
    item: {
        padding: tokens.spacingHorizontalL,
    },
    bordered: {
        borderTop: `1px solid ${tokens.colorNeutralStroke2}`,
    },
    skeleton: {
        borderTop: `1px solid transparent`, // we do this so the height between the skeleton and the item is the same
    },
    empty: {
        textAlign: 'center',
        backgroundColor: tokens.colorNeutralBackground5,
        padding: `${tokens.spacingVerticalXL} ${tokens.spacingHorizontalXL}`,
    },
});
