import { format, isValid, parse } from 'date-fns';
import { de, deAT, enUS } from 'date-fns/locale';

import { defaultLanguage, supportedLanguages } from '../config';
import { useAccount } from '../hooks';
import { Role } from './models';

const browserLanguage = window.navigator.language;
const language = supportedLanguages.find((language) => language.locale === browserLanguage) ?? defaultLanguage;
const languageMap = new Map([
    ['de-DE', de],
    ['de-AT', deAT],
    ['en-US', enUS],
]);
const locale = languageMap.get(language.locale);

const exactTimeFormat = 'HH:mm:ss.SSS';

export const parseTime = (value: string) => parse(value, 'p', 0, { locale });
export const formatTime = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return format(value, 'p', { locale });
};

export const parseExactTime = (value: string) => parse(value, exactTimeFormat, 0, { locale });
export const formatExactTime = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return format(value, exactTimeFormat, { locale });
};

export const parseDate = (value: string) => parse(value, 'PP', 0, { locale });
export const formatDate = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return format(value, 'PP', { locale });
};

const dateLength = formatDate(new Date()).length;

export const parseFullDate = (value: string) => parse(value, 'Pp', 0, { locale });
export const formatFullDate = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return format(value, 'Pp', { locale });
};

export const parseExactDate = (value: string) => parse(value, 'PPpp', 0, { locale });
export const formatExactDate = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return format(value, 'PPpp', { locale });
};

export const parseDetailDate = (value: string) => {
    const datePart = value.substring(0, dateLength).trim();
    const timePart = value.substring(dateLength).trim();

    const date = parseDate(datePart);
    const time = parseExactTime(timePart);

    if (isValid(date) && isValid(time)) {
        time.setFullYear(date.getFullYear());
        time.setMonth(date.getMonth());
        time.setDate(date.getDate());

        return time;
    }

    return new Date('Invalid Date');
};
export const formatDetailDate = (date: Date | string) => {
    const value = typeof date === 'string' ? new Date(date) : date;

    return format(value, `PP ${exactTimeFormat}`, { locale });
};

const formatters = new Map<DateFormat, (date: Date | string) => string>([
    ['time', formatTime],
    ['exact-time', formatExactTime],
    ['date', formatDate],
    ['full-date', formatFullDate],
    ['exact-date', formatExactDate],
    ['detail-date', formatDetailDate],
]);

export type DateFormat = 'time' | 'exact-time' | 'date' | 'full-date' | 'exact-date' | 'detail-date';

export type FormattedDateProps = {
    value: string | Date | undefined;
    format?: DateFormat;
};

export const FormattedDate: React.FC<FormattedDateProps> = (props) => {
    const { hasRoles } = useAccount();

    let format = props.format ?? 'full-date';

    if (hasRoles([Role.TechnicalUser]) && (format === 'full-date' || format === 'exact-date')) {
        format = 'detail-date';
    }

    const formatter = formatters.get(format);

    return <>{props.value && formatter?.(props.value)}</>;
};
