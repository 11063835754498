import { RefreshToken } from './models';

const storageKey = 'refreshToken';

export function getRefreshToken(): RefreshToken | undefined {
    const stored = localStorage.getItem(storageKey);

    if (!stored) {
        return;
    }

    return JSON.parse(stored);
}

export function storeRefreshToken(refreshToken: RefreshToken) {
    localStorage.setItem(storageKey, JSON.stringify(refreshToken));
}
