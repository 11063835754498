import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { ChevronLeft24Regular, ChevronRight24Regular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';

export type PaginationProps = {
    page?: number;
    more?: boolean;
    onChange?: (page: number) => void;
    onPrevious?: () => void;
    onNext?: () => void;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
    const { t } = useTranslation();
    const classes = useStyles();
    const page = props.page && props.page > 0 ? props.page : 1;

    const onPrevious = () => {
        props.onChange?.(page - 1);
        props.onPrevious?.();
    };

    const onNext = () => {
        props.onChange?.(page + 1);
        props.onNext?.();
    };

    return (
        <section className={classes.root}>
            <Button className={classes.previous} icon={<ChevronLeft24Regular />} disabled={page === 1} onClick={onPrevious}>
                {t('previous')}
            </Button>
            <Button className={classes.current}>{page}</Button>
            <Button className={classes.next} icon={<ChevronRight24Regular />} iconPosition="after" disabled={!props.more} onClick={onNext}>
                {t('next')}
            </Button>
        </section>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyItems: 'end',
    },
    previous: {
        minWidth: 'unset',
        borderTopRightRadius: '0',
        borderBottomRightRadius: '0',
    },
    current: {
        pointerEvents: 'none',
        minWidth: 'unset',
        borderRadius: '0',
        ...shorthands.borderStyle('solid', 'none'),
    },
    next: {
        minWidth: 'unset',
        borderTopLeftRadius: '0',
        borderBottomLeftRadius: '0',
    },
});
