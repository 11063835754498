export function getItem<T>(storageKey: string): T | undefined {
    const storedItem = localStorage.getItem(storageKey);
    if (storedItem) {
        return JSON.parse(storedItem) as T;
    }
}

export function setItem<T>(storageKey: string, itemToStore: T) {
    localStorage.setItem(storageKey, JSON.stringify(itemToStore));
}
