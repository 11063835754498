import { Checkbox, CheckboxProps, Field, makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { Controller } from 'react-hook-form';

import { validate } from './helpers';
import { ControllerFieldProps } from './models';

export const CheckboxField: React.FC<CheckboxProps & ControllerFieldProps> = ({ control, rules, ...props }) => {
    const classes = useStyles();

    return (
        <Controller
            defaultValue={props.checked}
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field {...validate(fieldState)}>
                    <Checkbox
                        className={mergeClasses(
                            fieldState.invalid && classes.error,
                            fieldState.invalid && field.value && classes.checkedError,
                        )}
                        {...field}
                        {...props}
                        checked={field.value}
                    />
                </Field>
            )}
        />
    );
};

const useStyles = makeStyles({
    error: {
        color: tokens.colorPaletteRedForeground1,
        '--fui-Checkbox__indicator--borderColor': tokens.colorPaletteRedBorder2,
        ':hover': {
            color: tokens.colorPaletteRedForeground1,
            '--fui-Checkbox__indicator--borderColor': tokens.colorPaletteRedBorderActive,
        },
        ':active': {
            color: tokens.colorPaletteRedForeground1,
            '--fui-Checkbox__indicator--borderColor': tokens.colorPaletteRedBorderActive,
        },
    },
    checkedError: {
        '--fui-Checkbox__indicator--backgroundColor': tokens.colorPaletteRedBorder2,
        ':hover': {
            '--fui-Checkbox__indicator--backgroundColor': tokens.colorPaletteRedBorderActive,
        },
        ':active': {
            '--fui-Checkbox__indicator--backgroundColor': tokens.colorPaletteRedBorderActive,
        },
    },
});
