import { Button, makeStyles, MessageBar, MessageBarBody, MessageBarTitle, Spinner, Title2 } from '@fluentui/react-components';
import { useTranslation } from 'react-i18next';

import { Stack } from '../common';

export type FormGroupState = 'default' | 'disabled' | 'loading' | { error: string; description: string };

export type FormGroupProps = {
    title?: string;
    onCancel?: () => void;
    onSubmit?: () => void;
    cancelText?: string;
    submitText?: string;
    submitDisabled?: boolean;
    state?: FormGroupState;
};

export const FormGroup: React.FC<React.PropsWithChildren<FormGroupProps>> = ({ state, ...props }) => {
    const classes = useStyles();

    const { t } = useTranslation('common');

    return (
        <fieldset className={classes.fieldset} disabled={state === 'loading'}>
            <Stack>
                {props.title && <Title2>{props.title}</Title2>}

                {props.children}

                {typeof state === 'object' && (
                    <MessageBar intent="error" layout="multiline">
                        <MessageBarBody>
                            <MessageBarTitle>{state.error}</MessageBarTitle>
                            {state.description}
                        </MessageBarBody>
                    </MessageBar>
                )}

                <Stack horizontal justify="end" spacing="small">
                    {props.onCancel && (
                        <Button disabled={state === 'disabled' || state === 'loading'} onClick={() => props.onCancel?.()}>
                            {props.cancelText ?? t('cancel')}
                        </Button>
                    )}
                    {props.onSubmit && (
                        <Button
                            disabled={state === 'disabled' || state === 'loading'}
                            onClick={() => props.onSubmit?.()}
                            appearance="primary"
                            icon={state === 'loading' ? <Spinner size="tiny" /> : undefined}
                        >
                            {props.submitText ?? t('save')}
                        </Button>
                    )}
                </Stack>
            </Stack>
        </fieldset>
    );
};

const useStyles = makeStyles({
    fieldset: {
        border: 'none',
        margin: 0,
        padding: 0,
    },
});
