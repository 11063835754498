import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { PropsWithChildren } from 'react';

import { BackButton } from './BackButton';

export type PageProps = {
    className?: string;
    showBack?: boolean;
};

export const Page: React.FC<PropsWithChildren<PageProps>> = (props) => {
    const classes = useStyles();

    return (
        <div className={mergeClasses(classes.root, props.className)}>
            {props.children}
            {props.showBack && (
                <div className={classes.back}>
                    <BackButton />
                </div>
            )}
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px',
    },
    back: {
        '@media print': {
            display: 'none',
        },
    },
});
