import { Button } from '@fluentui/react-components';
import { ArrowLeftRegular } from '@fluentui/react-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePageHistory } from '../hooks';

export const BackButton: React.FC = () => {
    const navigate = useNavigate();

    const { t } = useTranslation('common');
    const { length } = usePageHistory();

    const back = () => {
        if (length > 1) {
            navigate(-1);
        } else {
            navigate('..');
        }
    };

    return (
        <Button icon={<ArrowLeftRegular />} onClick={back} appearance="transparent">
            {t('back')}
        </Button>
    );
};
