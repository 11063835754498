import { makeStyles, mergeClasses, tokens } from '@fluentui/react-components';
import { useMediaQuery } from 'react-responsive';
import { Outlet } from 'react-router-dom';

import { usePageHistory } from '../hooks';
import { Brand } from './Brand';
import { Header } from './Header';
import { LeftMenu } from './LeftMenu';
import { MessageToaster } from './MessageToaster';
import { StaleDataDialog } from './StaleDataDialog';

export const Layout: React.FC = () => {
    usePageHistory();

    const classes = useStyles();
    const wide = useMediaQuery({ minWidth: 768 });

    return (
        <>
            <main className={mergeClasses(classes.root, !wide && classes.responsiveRoot)}>
                {wide && <Brand />}
                <Header className={mergeClasses(!wide && classes.responsiveHeader)} responsive={!wide} />
                {wide && <LeftMenu />}
                <div className={mergeClasses(classes.content, !wide && classes.responsiveContent)}>
                    <Outlet />
                </div>
            </main>
            <MessageToaster />
            <StaleDataDialog />
        </>
    );
};

const useStyles = makeStyles({
    root: {
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: '260px auto',
        gridTemplateRows: '60px auto',
        position: 'relative',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        overflow: 'hidden',
        '@media print': {
            height: 'unset',
            overflow: 'visible',
        },
    },
    content: {
        backgroundColor: tokens.colorNeutralBackground3,
        boxShadow: tokens.shadow16,
        zIndex: 100,
        padding: tokens.spacingHorizontalXXL,
        overflow: 'auto',
        '@media print': {
            padding: '0',
            overflow: 'visible',

            boxShadow: 'unset',
        },
    },
    responsiveRoot: {
        height: 'unset',
        display: 'block',
        position: 'relative',
        overflow: 'unset',
    },
    responsiveContent: {
        minHeight: [`calc(100vh - 84px)`, `calc(100dvh - 84px)`],
        padding: tokens.spacingHorizontalM,
    },
    responsiveHeader: {
        zIndex: 200,
        position: 'sticky',
        top: 0,
    },
});
