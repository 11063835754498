export function humanize(text: string) {
    const excludedWords = ['TaC', 'PoA', 'PoI', 'SoF', 'SoW'];
    // make exception words uppercase so they don't get split by the regex
    excludedWords.forEach((word) => (text = text.replace(word, word.toUpperCase())));

    text = text
        .replace(/((^[a-z]+)|([0-9]+)|([A-Z]{1}[a-z]+)|([A-Z]+(?=([A-Z][a-z])|($)|([0-9]))))/g, ' $1')
        .trim()
        .replace(/^./, (value) => value.toUpperCase());

    // reset back to pascal case for exception words
    excludedWords.forEach((word) => (text = text.replace(word.toUpperCase(), word)));

    return text;
}

const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

export function random(length: number) {
    let result = ' ';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
}
