import { AccountInfo } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { Button, makeStyles, mergeClasses, Persona, tokens } from '@fluentui/react-components';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { validate } from 'uuid';

import { humanize } from '../common';
import { useAccount } from '../hooks';

export type UserFlyoutMenuProps = {
    account: AccountInfo;
    preview?: boolean;
};

export const UserFlyoutMenu: React.FC<UserFlyoutMenuProps> = ({ account, preview }) => {
    const classes = useStyles();
    const { t } = useTranslation('common');
    const { instance } = useMsal();

    const { roles } = useAccount();

    const humanizedRoles = useMemo(() => {
        return roles.map((role) =>
            role
                .split('_')
                .map((segment) => {
                    if (validate(segment)) {
                        return segment;
                    } else {
                        return humanize(segment);
                    }
                })
                .join(' '),
        );
    }, [roles]);

    const [listedRoles, remainingRoles] = preview ? [humanizedRoles.slice(0, 3), humanizedRoles.slice(3)] : [humanizedRoles, []];

    return (
        <div className={mergeClasses(classes.root, preview && classes.preview)}>
            <Persona
                size="large"
                name={account.name}
                textAlignment="start"
                secondaryText={{
                    className: classes.role,
                    children: (
                        <>
                            {preview ? (
                                <span>
                                    {listedRoles.join(', ')}
                                    {remainingRoles.length && <> and +{remainingRoles.length} more</>}
                                </span>
                            ) : (
                                listedRoles.map((role, index) => <span key={index}>{role}</span>)
                            )}
                        </>
                    ),
                }}
            />
            <div className={classes.separator} />
            <Button className={classes.button} appearance="subtle" onClick={() => instance.logout()}>
                {t('signOut')}
            </Button>
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        maxWidth: '480px',
        minWidth: '280px',
        gap: tokens.spacingVerticalXS,
    },
    preview: {
        maxWidth: 'unset',
        minWidth: 'unset',
    },
    role: {
        marginTop: tokens.spacingVerticalXS,
        display: 'flex',
        flexDirection: 'column',
    },
    separator: {
        height: '1px',
        backgroundColor: tokens.colorNeutralStroke2,
        marginTop: tokens.spacingVerticalXS,
    },
    button: {
        justifyContent: 'start',
    },
});
