import { Body1, ButtonProps } from '@fluentui/react-components';
import { forwardRef, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

import { FormGroup } from '../forms';
import { DialogButton } from './DialogButton';

export type ConfirmDialogProps = {
    title: string;
    content: string;
};

// Wrapper because confirm dialog exposes onCancel instead of onDismissed which is expected by the DialogButton...
const ConfirmForm: React.FC<{ onDismissed?: (confirmed: boolean) => void } & ConfirmDialogProps> = ({ title, content, onDismissed }) => {
    const { t } = useTranslation('common');

    return (
        <FormGroup
            title={title}
            cancelText={t('cancel')}
            submitText={t('confirm')}
            onCancel={() => onDismissed?.(false)}
            onSubmit={() => onDismissed?.(true)}
        >
            <Body1>{content}</Body1>
        </FormGroup>
    );
};

export type ConfirmButtonProps = Pick<ButtonProps, 'icon'> & {
    dialog: ConfirmDialogProps;
    onConfirm?: () => void;
    onCancel?: () => void;
};

export const ConfirmButton = forwardRef<HTMLButtonElement, PropsWithChildren<ConfirmButtonProps>>(
    ({ onCancel, onConfirm, dialog, ...button }, ref) => (
        <DialogButton
            {...button}
            ref={ref}
            inline={!button.children}
            dialog={
                <ConfirmForm
                    title={dialog.title}
                    content={dialog.content}
                    onDismissed={(confirmed) => {
                        if (confirmed) {
                            onConfirm?.();
                        } else {
                            onCancel?.();
                        }
                    }}
                />
            }
        />
    ),
);
