import { Button, ButtonProps, makeStyles, mergeClasses, shorthands, tokens } from '@fluentui/react-components';

export const InlineIconButton: React.FC<ButtonProps> = ({ className, children, ...props }) => {
    const classes = useStyles();

    return (
        <Button appearance="transparent" className={mergeClasses(classes.root, className)} {...props}>
            {children}
        </Button>
    );
};

const useStyles = makeStyles({
    root: {
        fontWeight: tokens.fontWeightRegular,
        minWidth: 'unset',
        padding: '0',
        ...shorthands.borderWidth('0'),
    },
});
