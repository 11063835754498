import { Field, FieldProps, Input, InputProps, makeStyles, mergeClasses } from '@fluentui/react-components';
import { Controller } from 'react-hook-form';

import { validate } from './helpers';
import { ControllerFieldProps } from './models';

function mapEvent(event: any) {
    const sources = [event.target['valueAsNumber'], event.target['value'], event];

    return sources.find((source) => source !== undefined && source !== null && !Number.isNaN(source)) ?? null;
}

export type TextFieldProps = Omit<InputProps, 'type'> &
    Pick<FieldProps, 'hint'> & {
        label?: string;
        type?: 'text' | 'email' | 'password' | 'search' | 'tel' | 'url' | 'number';
    };

export const TextField: React.FC<TextFieldProps & ControllerFieldProps> = ({ control, rules, className, label, hint, ...props }) => {
    const classes = useStyles();

    return (
        <Controller
            name={props.name}
            control={control}
            rules={rules}
            render={({ field, fieldState }) => (
                <Field label={label} hint={hint} {...validate(fieldState)} className={className}>
                    <Input
                        {...props}
                        {...field}
                        className={props.input?.className}
                        onChange={(event) => field.onChange(event.target.value ? mapEvent(event) : null)}
                        input={{
                            ...props.input,
                            inputMode: props.type === 'number' ? 'decimal' : undefined,
                            className: mergeClasses(props.type === 'number' && classes.number, props.input?.className),
                        }}
                        value={field.value ?? ''}
                    />
                </Field>
            )}
        />
    );
};

const useStyles = makeStyles({
    number: {
        textAlign: 'right',
        MozAppearance: 'textfield',
        WebkitAppearance: 'textfield',
        '::-webkit-outer-spin-button': {
            margin: '0',
            WebkitAppearance: 'none',
        },
        '::-webkit-inner-spin-button': {
            margin: '0',
            WebkitAppearance: 'none',
        },
    },
});
