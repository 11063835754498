import {
    Dialog,
    DialogBody,
    DialogContent,
    DialogProps,
    DialogSurface,
    makeStyles,
    mergeClasses,
    tokens,
} from '@fluentui/react-components';
import { PropsWithChildren, useEffect, useState } from 'react';

export type WrappedDialogProps = Omit<DialogProps, 'children'> & {
    size?: 'default' | 'medium' | 'large';
};

export const WrappedDialog: React.FC<PropsWithChildren<WrappedDialogProps>> = ({ size, open, modalType, children, ...props }) => {
    const classes = useStyles();
    const [shown, setShown] = useState(false);

    useEffect(() => {
        if (open) {
            setShown(true);
        } else {
            setTimeout(() => {
                setShown(false);
            }, 500);
        }
    }, [open]);

    return (
        <Dialog modalType={modalType ?? 'alert'} open={open} {...props}>
            <DialogSurface
                backdrop={{ className: classes.surfaceBackdrop }}
                className={mergeClasses(
                    classes.surface,
                    size === 'medium' && classes.surfaceMedium,
                    size === 'large' && classes.surfaceLarge,
                )}
            >
                <DialogBody className={classes.body}>
                    <DialogContent>{shown && children}</DialogContent>
                </DialogBody>
            </DialogSurface>
        </Dialog>
    );
};

const useStyles = makeStyles({
    surface: {
        width: 'calc(100vw - 24px)',
        maxWidth: '560px',
        overflow: 'hidden',
    },
    surfaceMedium: {
        maxWidth: '960px',
    },
    surfaceLarge: {
        maxWidth: '1440px',
    },
    surfaceBackdrop: {
        backgroundColor: tokens.colorBackgroundOverlay,
    },
    body: {
        gap: '0',
    },
});
