import { MessageBarIntent, ToastIntent } from '@fluentui/react-components';
import { useMemo } from 'react';
import { createGlobalState } from 'react-hooks-global-state';
import { v4 as uuid } from 'uuid';

export type Message = {
    id: string;
    time: Date;
    type: ToastIntent;
    message: string;
    details?: string;
};

type MessageState = {
    messages: Message[];
};

const initialState: MessageState = { messages: [] };

const { useGlobalState } = createGlobalState(initialState);

export const usePageMessages = () => {
    const [messages, setMessages] = useGlobalState('messages');

    const addMessage = (type: MessageBarIntent) => (message: string, details?: string) =>
        setMessages((messages) => [
            ...messages,
            {
                id: uuid(),
                time: new Date(),
                type,
                message,
                details,
            },
        ]);

    const { addSuccess, addError, addInfo, addWarning } = useMemo(() => {
        return {
            addSuccess: addMessage('success'),
            addError: addMessage('error'),
            addInfo: addMessage('info'),
            addWarning: addMessage('warning'),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
        messages,
        addSuccess,
        addError,
        addInfo,
        addWarning,
    };
};
