import { makeStyles, tokens } from '@fluentui/react-components';

import { ErrorMessage } from '../common/ErrorMessage';

export const ErrorPage: React.FC = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <ErrorMessage errorType="general" />
        </div>
    );
};

const useStyles = makeStyles({
    root: {
        padding: tokens.spacingHorizontalXXL,
    },
});
